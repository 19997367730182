import React, { FC } from "react";
import { useWindowDimensions } from "../../hooks";
import ButtonBack from "../Button/ButtonBack/ButtonBack";
import "./Title.scss";

interface Props {
  redirect?: string;
  children?: any;
}

const Title: FC<Props> = ({ children, redirect }) => {
  // const { width, height } = useWindowDimensions();
  //   <h1 className={width >= 1270 ? "Title with-button" : "Title"}>
  //   {width >= 1270 && <ButtonBack redirect={redirect} />}
  //   {children}
  // </h1>

  return <h1 className={"Title"}>{children}</h1>;
};

export default Title;
