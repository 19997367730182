import React, { useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./HyperX2.scss";
import Title from "../../components/Title/Title";
import mobileHero from "./img/hero.png";
import desktopHero from "./img/hero-desktop.png";
import Icon01 from "./img/img1.svg";
import Icon02 from "./img/img2.svg";
import Icon03 from "./img/img3.svg";
import Icon04 from "./img/img4.svg";
import x2red from "./img/x2-red.png";
import x2green from "./img/x2-green.png";
import x2orange from "./img/x2-orange.png";
import x2black from "./img/x2-black.png";
import x2gold from "./img/x2-gold.png";
import x2cobalt from "./img/x2-cobalt.png";
import gloBg from "./img/glo.png";
import glox2Bg from "./img/glox2.png";
import { ReactComponent as IconBack } from "./img/click-icon.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { useWindowDimensions } from "../../hooks";
import { Link } from "react-router-dom";
import {Navigation} from 'swiper';
import ArrowButton from "../../components/ArrowButton/ArrowButton";
import Badge from "../../components/Badge/Badge";
function HyperX2() {
  const { width } = useWindowDimensions();
  const [ cardIndex, setCardIndex] = useState(0);

  const cards = (
    <>
      <div className="card">
        <div className="top" style={{backgroundImage: "url("+gloBg+")"}}>
          <span>hyper+ <br/>UNIQ <br/></span>
        </div>
        <div className="body">
          <p className="desc">
            <span>Warianty kolorystyczne </span>4<br/>
            <span>Wymienna panele boczne </span>Tak<br/>
            <span>Wymiary (mm) </span>82.5 x 45.5 x 22.0<br/>
            <span>Waga (g) </span>115.6<br/>
            <span>Min długość sesji </span>4/3 min<br/>
            <span>Liczba sesji </span>20<br/>
            <span>Liczba kolejnych sesji </span>3<br/>
            <span>Diody LED sygnalizacja </span>4<br/>
            <span>Dwa tryby pracy </span>Tak<br/>
            <span>Oddzielny przycisk BOOST </span>Nie<br/>
            <span>Czas rozruchu </span>20 STANDARD / 15 BOOST<br/>
            <span>Forma wkładów neo™ </span>Demi Slim
          </p>
        </div>
      </div>
      <div className="card">
        <div className="top" style={{backgroundImage: "url("+glox2Bg+")"}}>
        <span>hyper X2 <br/></span>
        </div>
        <div className="body">
          <p className="desc">
            <span>Warianty kolorystyczne </span>6 (w tym 3 dwukolorowe)<br/>
            <span>Wymienna panele boczne </span>Nie<br/>
            <span>Wymiary (mm) </span>77.5 x 43.3 x 21.4<br/>
            <span>Waga (g) </span>102<br/>
            <span>Min długość sesji </span>4/3 min<br/>
            <span>Liczba sesji </span>do 22<br/>
            <span>Liczba kolejnych sesji </span>2<br/>
            <span>Diody LED sygnalizacja </span>9<br/>
            <span>Dwa tryby pracy </span>Tak<br/>
            <span>Oddzielny przycisk BOOST </span>Tak<br/>
            <span>Czas rozruchu </span>30 STANDARD / 20 BOOST<br/>
            <span>Forma wkładów neo™ </span>Demi Slim
          </p>
        </div>
      </div>
    </>
  )
  return (
    <PageLayout>
      <section className="HyperX2">
        <div className="hero-section">
          <Badge category="Podgrzewacz tytoniu"/>
          <div className="hero-image">
            <picture>
              <source media="(max-width: 1019px)" srcSet={mobileHero} />
              <source media="(min-width: 1020px)" srcSet={desktopHero} />
              <img className="bg" src={desktopHero} alt="A wide image" />
            </picture>
          </div>
          <div className="wrapper">
            <div className="hero-content">
              <Title></Title>
              
            </div>
          </div>
        </div>

        <section className="device-section">
          <div className="card devices-card">
            <div className="top">
              <h2>Nowe glo™ hyper X2</h2>
            </div>
            <div className="body">
              <p>Urządzenie glo™️ hyper X2 to nasz najbardziej zaawansowany podgrzewacz tytoniu, ale równocześnie niezwykle intuicyjny w obsłudze.</p>
              <h3>Dostępne w 6 wyjątkowych wersjach kolorystycznych:</h3>
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                centeredSlides={true}
                className="devices"
                breakpoints={{
                  1020: {
                    slidesPerView: 6,
                    spaceBetween: 20,
                    centeredSlides: false,
                  },
                  1270: {
                    slidesPerView: 6,
                    spaceBetween: 40,
                    centeredSlides: false,
                  },
                }}
                navigation={{
                  nextEl: ".devices-btn-next",
                  prevEl: ".devices-btn-prev",
                }}
                modules={[Navigation]}
              >
                <SwiperSlide>
                  <div className="device">
                    <img src={x2red} />
                    <p>black/red</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="device">
                    <img src={x2green} />
                    <p>green/pine</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="device">
                    <img src={x2orange} />
                    <p>orange</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="device">
                    <img src={x2black} />
                    <p>black</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="device">
                    <img src={x2gold} />
                    <p>white/gold</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="device">
                    <img src={x2cobalt} />
                    <p>blue</p>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className="devices-btn devices-btn-next">
                <ArrowButton color="white" />
              </div>
              <div className="devices-btn devices-btn-prev">
                <ArrowButton color="white" />
              </div>
            </div>
          </div>
        </section>

        <h2 className="color-white">Czym wyróżnia się nowe glo™ HYPER X2?</h2>
        <Swiper
          slidesPerView={1.2}
          spaceBetween={16}
          centeredSlides={true}
          className="recommend-swiper"
          breakpoints={{
            1020: {
              slidesPerView: 5,
              spaceBetween: 10,
              centeredSlides: false,
            },
            1270: {
              slidesPerView: 5,
              spaceBetween: 20,
              centeredSlides: false,
            },
          }}
        >
          <SwiperSlide>
            <div className="recommend-swiper-slide">
              <div className="top">
                <div className="number">1</div>
              </div>
              <div className="body">
                <h3 className="title">Intuicyjny w&nbsp;obsłudze</h3>
                <p className="desc">
                  Dwa tryby pracy w tym osobny przycisk BOOST dla zwiększenia intensywności doświadczania prawdziwego tytoniu.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="recommend-swiper-slide">
              <div className="top">
                <div className="number">2</div>
              </div>
              <div className="body">
                <h3 className="title">Funkcjonalny i&nbsp;ergonomiczny</h3>
                <p className="desc">
                  Poręczny i lżejszy w kieszeni - 13% lżejszy i 7% mniejszy w porównaniu do poprzedniego urządzenia z serii glo™ hyper+
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="recommend-swiper-slide">
              <div className="top">
                <div className="number">3</div>
              </div>
              <div className="body">
                <h3 className="title">Lepsza kontrola</h3>
                <p className="desc">
                  Ulepszony pod względem widoczności wskaźnik LED wyświetlający status urządzenia
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="recommend-swiper-slide">
              <div className="top">
                <div className="number">4</div>
              </div>
              <div className="body">
                <h3 className="title">Estetyczny</h3>
                <p className="desc">
                  Obrotowy pierścień zamykający przesłonę dla lepszej ochrony przed zabrudzeniami
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="recommend-swiper-slide">
              <div className="top">
                <div className="number">5</div>
              </div>
              <div className="body">
                <h3 className="title">6 wyjątkowych kolorów</h3>
                <p className="desc">
                Warianty kolorystyczne, dzięki którym każdy może wyrazić swój niepowtarzalny styl
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <div className="qr">
          <div className="wrapper">
            <header className="header">
              <h2 className="title">Czym różni się glo™ hyper X2  od glo™ hyper+ UNIQ?</h2>
            </header>

            { width < 1020 ? 
            <>
              <div className="card-control">
                <div className={cardIndex === 0 ? "control-btn active": "control-btn"} onClick={()=>setCardIndex(0)}>
                  hyper+ UNIQ 
                </div>
                <div className={cardIndex === 1 ? "control-btn active": "control-btn"} onClick={()=>setCardIndex(1)}>
                  hyper X2 
                </div>
              </div>
              <div className="cards" style={{transform: "translate("+(-50*cardIndex)+"%,0)"}}>
                { cards }
              </div>
            </> 
            :
            <div className="cards">
              <div className="legend">
                <p>Warianty kolorystyczne<br/>
                  Wymienna panele boczne<br/>
                  Wymiary (mm) <br/>
                  Waga (g)<br/>
                  Min długość sesji<br/>
                  Liczba sesji<br/>
                  Liczba kolejnych sesji<br/>
                  Diody LED sygnalizacja<br/>
                  Dwa tryby pracy<br/>
                  Oddzielny przycisk BOOST<br/>
                  Czas rozruchu<br/>
                  Forma wkładów neo™<br/>
                </p>
              </div>
              { cards }
            </div>
            }
          </div>
        </div>
            

        <div className="footer">
          <h2>Co zawiera w sobie nowy zestaw startowy glo™ hyper X2?</h2>
          <div className="cards">
            <div className="card">
              <img src={Icon01} alt="az-icon" />
              <p className="desc">Urządzenie glo™ hyper X2 </p>
            </div>
            <div className="card">
              <img src={Icon02} alt="az-icon" />
              <p className="desc">Szczoteczka do&nbsp;czyszczenia</p>
            </div>
            <div className="card">
              <img src={Icon03} alt="az-icon" />
              <p className="desc">Kabel USB-C</p>
            </div>
            <div className="card">
              <img src={Icon04} alt="az-icon" />
              <p className="desc">Instrukcja obsługi</p>
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  );
}

export default HyperX2;
