import React, { useEffect, useState } from 'react';
import PageLayout from "../../components/PageLayout/PageLayout";
import { SubmitHandler, useForm, FieldValues, FieldErrors } from 'react-hook-form';
import '../../theme/forms.scss';
import { Link, useLocation } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { useNavigate  } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetPass } from '../../store/actions/user';
import { useAppDispatch } from '../../hooks';

interface loginProps {
  emailAddress: 'string';
}

function NewPassPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { register, setValue, handleSubmit, getValues, formState: { errors } } = useForm();
  const [tempToken, setTempToken] = useState('')

  const onSubmit = ({password, password2}: FieldValues) => {
    dispatch(resetPass({user: {password: password}}, tempToken, navigate))
  }

  const onError = (errors: FieldErrors) => {
    //console.log(errors)
  }

  useEffect(()=>{
    if (location.search.indexOf("reset") !== -1 ) {
      let token = location.search.split("=")[1];
      setTempToken(token)
    }
  },[])

  return (
    <PageLayout>
      <div className="page NewPass">
        <h1>Ustaw nowe hasło</h1>
        <p className="color-secondary text-center"><strong>Min. 8 znaków, 1 duża litera, 1 mała litera, 1 cyfra, 1 znak specjalny</strong></p>
        <form>
          <div className="input-item">
            <label htmlFor="password">Hasło</label>
            <input className={ errors.password && 'error-input' } type="password" id="password" {...register("password", {required: true, pattern:/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.\?<>()\[\]\{\}+\-_\/\\:;"'|=]).{8,}$/})} ></input>
            {errors.password && errors.password.type === "pattern" && (
              <p className="error">Nieprawidłowa wartość</p>
            )}
            {errors.password && errors.password.type === "required" && (
              <p className="error">Pole wymagane</p>
            )}
          </div>
          <div className="input-item">
            <label htmlFor="password2">Powtórz hasło</label>
            <input className={ errors.password2 && 'error-input' } type="password" id="password2" {...register("password2", {required: true, pattern:/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.\?<>()\[\]\{\}+\-_\/\\:;"'|=]).{8,}$/, validate: value => value === getValues('password')})} ></input>
            {errors.password2 && errors.password2.type === "pattern" && (
              <p className="error">Nieprawidłowa wartość</p>
            )}
            {errors.password2 && errors.password2.type === "validate" && (
              <p className="error">Hasła nie są identyczne</p>
            )}
            {errors.password2 && errors.password2.type === "required" && (
              <p className="error">Pole wymagane</p>
            )}
          </div>
          <Button onClick={ handleSubmit(onSubmit, onError) }>Zapisz</Button>
          <div className="text-center margin-20">
            <Link to="/">Powrót do logowania</Link>
          </div>
        </form>
      </div>
    </PageLayout>
  );
}

export default NewPassPage
