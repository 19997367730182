
import React, { useEffect, useState, } from 'react';
import { useAppDispatch, useWindowDimensions } from '../../hooks';
import { otp, updateUser } from '../../store/actions/user';
import { useForm, SubmitHandler, FieldValues, FieldErrors ,Controller} from 'react-hook-form';
import { getUser } from '../../store/reducers/user';
import { useDispatch, useSelector } from 'react-redux';
import Countdown, { zeroPad } from 'react-countdown';
import Button from '../../components/Button/Button';

function Consents() {
  const dispatch = useAppDispatch()
  const { height, width } = useWindowDimensions();
  const user = useSelector(getUser);
  const { register, setValue, handleSubmit, getValues, reset, formState: { errors} } = useForm();
  const formValues = getValues()
  const [otpSendedAtLeastOnce, setOtpSendedAtLeastOnce] = useState(false)
  const [otpButton, setOtpButton] = useState(false)
  const [timer, setTimer] = useState(Date.now())
  const [emailContact, setEmailContact] = useState(false)
  const [phoneContact, setPhoneContact] = useState(false)

  const onSubmit = (data: FieldValues) => {
    dispatch(updateUser({ user: {
      phoneContact: phoneContact,
      emailContact: emailContact,
      marketingConsent: phoneContact || emailContact ? true : false
    }}))
    // setTimer(0)
    // setOtpButton(true)
    // reset({otp: ""});
    // setOtpSendedAtLeastOnce(false)
  }

  const onError = (errors: FieldErrors) => {
    //console.log(errors)
  }

  useEffect(() => {
    setEmailContact(user?.emailContact)
    setPhoneContact(user?.phoneContact)
  }, [user.firstName])


  const renderer = ({ minutes, seconds, completed } :any) => {
    if (completed) {
      setOtpButton(true)
      return <p className="timer disabled">00:00</p>;
    } else {
      return <p className="timer">{zeroPad(minutes)}:{zeroPad(seconds)}</p>;
    }
  };


  return (
    <div>
      <p>Zgoda na przetwarzanie danych osobowych przez British American Tobacco Polska Trading sp. z o.o. z siedzibą w Warszawie, w celu przesyłania mi informacji na temat produktów i usług oferowanych przez BAT.</p>
      <p>Jako formę kontaktu na potrzeby przesyłania informacji na temat produktów i usług BAT wybieram:</p>
    <div className="checkbox-item checkbox-item-marketing">
      <div className="label-text" onClick={() =>{setEmailContact(!emailContact)}}>
        wiadomości przesyłane drogą elektroniczną (e-mail, SMS, serwisy internetowe, okienka pop up)
      </div>
      <input
        type="checkbox"
        id="emailContact"
        defaultChecked={user.emailContact}
      />
      <label onClick={() =>{setEmailContact(!emailContact)}}
        htmlFor={"emailContact"}
        className={emailContact ? "check active" : "check"}
      >
        {emailContact ? "Tak" : "Nie"}
      </label>
    </div>
    <div className="checkbox-item checkbox-item-marketing">
      <div className="label-text" onClick={() =>setPhoneContact(!phoneContact)}>
        połączenia głosowe (rozmowy telefoniczne, komunikaty IVR)
      </div>
      <input
        type="checkbox"
        id="phoneContact" 
        defaultChecked={user.phoneContact}
      />
      <label onClick={() =>setPhoneContact(!phoneContact)}
        htmlFor={"phoneContact"}
        className={phoneContact ? "check active" : "check"}
      >
        {phoneContact ? "Tak" : "Nie"}
      </label>
    </div>
    <div className="btn-group">
      <Button onClick={ handleSubmit(onSubmit, onError) }>Zapisz zgody</Button>
    </div>

    {/* <p><strong>Na numer telefonu {user?.mobilePhone} wyślemy kod autoryzacyjny. </strong></p>
        <p>Wprowadź kod, aby zapisać zmiany w profilu.</p>
        <div className="input-item send-code-group">
          <div>
            <label htmlFor="otp">Kod autoryzacyjny</label>
            <input disabled={!otpSendedAtLeastOnce} className={ errors?.otp && 'error-input' } {...register("otp", {required: true})} defaultValue={ user.otp } />
            {errors.otp && errors.otp.type === "required" && (
              <p className="error">Uzupełnij pole</p>
            )}
          </div>
          {!otpSendedAtLeastOnce && <Button className='send-code' onClick={ () => { 
            dispatch(otp()); 
            setOtpSendedAtLeastOnce(true);
            setTimer(Date.now() + 300000);
            setOtpButton(false);
          } }>Wyślij SMS</Button> }
        </div>
        {otpSendedAtLeastOnce &&
          <div className='sended-sms-group'>
            <p>SMS nie przyszedł?</p>
            { otpButton ? 
              <p className='send-again' onClick={() => { 
                setTimer(Date.now() + 300000);
                setOtpButton(false);
                dispatch(otp());
              }}>Wyślij ponowienie</p> :
            <div className="send-again disabled">Wyślij kod ponownie</div> }
            <Countdown
              date={timer}
              key={timer}
              zeroPadTime={0}
              renderer={renderer}
            />
          </div>
        }
        <div className="btn-group">
          <Button className={!otpSendedAtLeastOnce ? "next disabled" : "next"} disabled={!otpSendedAtLeastOnce} onClick={ handleSubmit(onSubmit, onError) }>Zapisz dane</Button>
        </div> */}

    </div>
  );
}

export default Consents;