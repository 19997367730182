import { ThunkAction, Action } from '@reduxjs/toolkit';
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from 'redux-thunk';
import { userReducer } from './reducers/user';
import { uiReducer } from './reducers/ui';
import { accountReducer } from './reducers/account';
import { activationsReducer } from './reducers/activations';
import { notificationReducer } from './reducers/notifications';
import { rankingsReducer } from './reducers/rankings';
import { newsReducer } from './reducers/news';

// TODO: proper types
const loggerMiddleware = (store:any) => (next:any) => (action:any) => {
  console.log("Action type: "+ action.type);
  console.log("Action payload:", action.payload);
  console.log("State before:", store.getState());
  next(action);
  console.log("State after:", store.getState());
};

export const store = createStore(
  combineReducers({
    user: userReducer,
    ui: uiReducer,
    account: accountReducer,
    activations: activationsReducer,
    notifications: notificationReducer,
    rankings: rankingsReducer,
    news: newsReducer
  }),
  {
    user: {
      data: {
        firstName: ''
      },
      coupons: [],
      timer: {},
      submitting: false
    },
    account: {
      balance: {
        value: 0,
        current: 0,
        history: [],
        referral: {
          current: 0,
          history: []
        }
      },
      seasons: [],
      seasonsInfo: {
        current: '',
        list: []
      },
      incomeHistory: {
        count: 0,
        history: []
      },
      incomeHistorySales: {
        count: 0,
        history: []
      },
      incomeHistoryRefs: {
        count: 0,
        history: []
      },
      payoutHistory: [],
      prizeList:[]
    },
    ui: {
      theme: "light",
      menuShow: false,
      notifications: [],
      showNoti: true,
      contestInfo: localStorage.getItem("contestInfoViewed")
    },
    notifications: { notifications: [] },
    rankings: {
      list: [],
      loading:true,
      list_stats: []
    },
    news: {
      list: []
    },
    activations: {
      list: []
    }
   
  },
  compose(
    applyMiddleware(
      loggerMiddleware,
      thunk
    ),
  ),
)

store.subscribe(() => {
  //console.log('STATE SAVE')
  //saveState(store.getState())
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
