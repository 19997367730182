import { useEffect, useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import {
  useAnalyticsEventTracker,
  useAppDispatch,
  useAppSelector,
} from "../../hooks";
import { getUser } from "../../store/reducers/user";
import "./Dashboard.scss";
import DashboardSlider from "./DashboardSlider/DashboardSlider";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../hooks";

import { ReactComponent as HandShake } from "./img/hand-shake.svg";
import { ReactComponent as MoneyIcon } from "./img/money.svg";

import { balanceSummary, visitStats } from "../../store/actions/account";
import {
  getAccountBalance,
  getAccountProfit,
  getReferrals,
  getSeasons,
  getSeasonsInfo,
  getVisitStats,
} from "../../store/reducers/account";
import { showDrawer } from "../../store/actions/ui";
import { ReactComponent as Hand } from "./img/hand.svg";
import { ReactComponent as Clock } from "./img/clock.svg";
import MobileDetect from "mobile-detect";
//@ts-ignore
import PWAPrompt from "react-ios-pwa-prompt";

function Dashboard() {
  const navigate = useNavigate();
  const user = useAppSelector(getUser);
  const balance = useAppSelector(getAccountBalance);
  const visits = useAppSelector(getVisitStats);
  const profit = useAppSelector(getAccountProfit);
  const dispatch = useAppDispatch();
  // const gaEventTracker = useAnalyticsEventTracker("Dashboard Event");
  const timeToShowPopup = localStorage.getItem("POP_MODAL_TIME");
  const [device, setDevice] = useState<any>({});
  const [iOSPrompt, setiOSPrompt] = useState(false);
  const [missingUserData, setMissingUserData] = useState(false);

  const mainTask = visits?.currentPeriod?.isActive;

  const handleShowPopup = (modalsArray: any) => {
    if (modalsArray?.length > 0) {
      const date = new Date();
      const time = date.getTime();
      const weekInHours = 24 * 7;
      const serializeTime = timeToShowPopup ? JSON.parse(timeToShowPopup) : 0;

      if (serializeTime < time) {
        const diff = date.setTime(
          date.getTime() + weekInHours * 60 * 60 * 1000
        );
        window.localStorage.setItem("POP_MODAL_TIME", JSON.stringify(diff));
        dispatch(showDrawer(modalsArray[0]));
      }
    }
  };
  useEffect(() => {
    handleShowPopup(user?.modals);
  }, [user?.modals]);

  useEffect(() => {
    dispatch(visitStats());
    dispatch(balanceSummary());
    checkDevice();
  }, []);

  useEffect(() => {
    if (user?.firstName) {
      if (
        user?.dob != null &&
        user?.streetAddress != null &&
        user?.postalCode != null &&
        user?.cityName != null &&
        user?.taxOfficeName != null &&
        user?.iban != null
      ) {
        setMissingUserData(false);
      } else {
        setMissingUserData(true);
      }
    }
    if (user?.firstName && !user?.consent022024) {
      navigate("/zgoda/consent022024");
    }
  }, [user?.dob]);

  const getPWADisplayMode = () => {
    const isStandalone = window.matchMedia(
      "(display-mode: standalone)"
    ).matches;
    if (document.referrer.startsWith("android-app://")) {
      return "twa";
      //@ts-ignore
    } else if (navigator.standalone || isStandalone) {
      return "standalone";
    }
    return "browser";
  };

  const checkDevice = () => {
    const md = new MobileDetect(window.navigator.userAgent);
    const dev = {
      isMobile: md.mobile() ? true : false,
      isIos: md.is("iOS") ? true : false,
      isAndroid: md.is("AndroidOS") || md.is("Android") ? true : false,
      isPWA: getPWADisplayMode() === "standalone" ? true : false,
      //@ts-ignore
      installable: window.deferredPrompt ? true : false,
    };
    // console.log(dev)
    // console.log(getPWADisplayMode())
    setDevice(dev);
  };

  const installPwa = async () => {
    //@ts-ignore
    window.deferredPrompt?.prompt();
    //eslint-disable-next-line
    //@ts-ignore
    const { outcome } = await window.deferredPrompt.userChoice;
    // console.log(`User response to the install prompt: ${outcome}`);
    //@ts-ignore
    window.deferredPrompt = null;
  };

  const showPrompt = () => {
    setiOSPrompt(true);
  };

  return (
    <PageLayout>
      <div className="page Dashboard">
        <div className="user-info">
          <div className="hello">
            <div className="hello-name">
              <div>Witaj</div>
              <div className="user-name">{user?.firstName}</div>
              <div className="bp-number">
                <div>Twój&nbsp;numer&nbsp;BAT&nbsp;Partnera: </div>
                <div className="user-number">{user.referralCode}</div>
              </div>
            </div>

            <div className="account-balance">
              <div>Twoje dostępne saldo:</div>
              <span className="value">{balance} zł</span>
            </div>
          </div>
          {/* Oryginalna mechanika wypłacania: */}
          <div className="main-task">
            <div className="header">
              <h2 className="title">Główne zadanie:</h2>
              {mainTask ? (
                <>
                  <div className="value">
                    {visits?.currentPeriod?.payout} zł
                  </div>
                  <p className="disclaimer">(za każdą skuteczną rejestrację)</p>
                  <p className="date">
                    {formatDate(visits?.currentPeriod?.start, {
                      month: "2-digit",
                      day: "2-digit",
                    })}{" "}
                    -{" "}
                    {formatDate(visits?.currentPeriod?.end, {
                      month: "2-digit",
                      day: "2-digit",
                      year: "2-digit",
                    })}
                  </p>
                </>
              ) : (
                <div className="alt-desc">
                  <p>
                    Obecnie nie ma dostępnego głównego zadania w programie, ale
                    nie martw się!
                  </p>
                  <p>
                    {" "}
                    Pracujemy nad nową aktywnością, która pojawi się wkrótce.
                  </p>
                </div>
              )}
            </div>

            {mainTask && (
              <div className="earnings-info">
                <div>
                  <div className="legend-wrapper">
                    <div className="content">
                      <div>
                        <div className="item">
                          <HandShake /> Zarejestrowane wizyty:&nbsp;
                          {/* <strong>{currentMonth?.count}</strong> */}
                          <strong>{visits?.currentPeriod?.count}</strong>
                        </div>
                        <div className="item">
                          <MoneyIcon />
                          Nagrody:&nbsp;
                          <strong>{visits?.currentPeriod?.payout}</strong>
                        </div>

                        <p className="earnings-disclaimer">
                          *Wyświetlona kwota jest przybliżona. Czekamy na jej
                          potwierdzenie
                        </p>
                      </div>
                      <Button path="/rejestracja-wizyty">
                        Zarejestruj wizytę
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="dash-news">
          <h2>Co nowego?</h2>
          <DashboardSlider />
        </div>
        {device.isMobile &&
          (device.installable || device.isIos) &&
          !device.isPWA && (
            <div className="dash-shortcut dropshadow-box">
              <div className="dash-shortcut-content">
                <h2>
                  Korzystaj z BAT Partners HURT <br />w formie aplikacji.
                </h2>
                <ul>
                  <li>
                    <Hand />
                    <span>Łatwy dostęp</span>
                  </li>
                  <li>
                    <Clock />
                    <span>Sprawne korzystanie</span>
                  </li>
                </ul>
              </div>
              <div className="shortcut-box">
                <p>
                  Zamień stronę internetową <br /> na aplikację webową!
                </p>
                <Button
                  onClick={() => {
                    if (device.isIos) {
                      showPrompt();
                    } else if (device.isAndroid) {
                      installPwa();
                    }
                  }}
                >
                  Dodaj skrót
                </Button>
              </div>
            </div>
          )}
      </div>
      {iOSPrompt && (
        <PWAPrompt
          promptOnVisit={0}
          timesToShow={999}
          copyClosePrompt="Zamknij"
          permanentlyHideOnDismiss={false}
          onClose={() => {
            setTimeout(() => {
              setiOSPrompt(false);
            }, 500);
          }}
          copyTitle="Dodaj do ekranu początkowego"
          copyBody="Wrzuć ikonę na pulpit i odpalaj BAT Partners HURT jednym ruchem - szybciej niż kiedykolwiek."
          copyShareButtonLabel="1) Naciśnij przycisk udostępniania widoczny na środku dolnego paska nawigacyjnego."
          copyAddHomeButtonLabel="2) Naciśnij „Do ekranu początkowego”"
        />
      )}
    </PageLayout>
  );
}

export default Dashboard;
