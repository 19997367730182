import React, { Component, ReactComponentElement } from 'react'
import Modal from 'react-modal'
import { getUI } from '../../store/reducers/ui'
import { hideDrawer, showDrawer } from '../../store/actions/ui'
import { getUser } from '../../store/reducers/user'
import { ReactComponent as IconExclamation } from './img/icon-exclamation.svg';
import { ReactComponent as IconLocked } from './img/icon-locked.svg';
import { ReactComponent as IconPrize } from './img/icon-prize.svg';
import { ReactComponent as IconHourglass } from './img/icon-hourglass.svg';
import { ReactComponent as IconChecked } from './img/icon-checked.svg';
import { ReactComponent as IconCompleted } from './img/icon-completed.svg';
import Button from '../Button/Button';
import { errors, infos } from './messages';
import './Drawer.scss';
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks'

Modal.setAppElement('#root')

export default function Drawer() {
  const dispatch = useAppDispatch()
  const ui = useAppSelector(getUI)
  const user = useAppSelector(getUser)
  const navigate = useNavigate();

  const icons: { [icon: string]: any} = {
    'exclamation': <IconExclamation />,
    'locked': <IconLocked />,
    'hourglass': <IconHourglass />,
    'completed': <IconCompleted />
  }

  const handleSwipe = (e: any) => {
    if (e.direction === 16) {
      dispatch(hideDrawer())
    }
  }

  const drawerContent = () => {
    switch(ui.drawerType) {
      case 'batcode':
        return (
          <div className="drawer-inner drawer-batcode">
            <p>Twój numer BAT Partnera</p>
            <p className="bat-number">{ user?.referralCode }</p>
          </div>
        )
      case 'error':
        let errorName: string = ui.drawerData.name;
        const errorCode: string = ui.drawerData.errorCode;
         if (!errors[errorName]) errorName = "generic"
        return (
          <div className="drawer-inner drawer-error">
            { errors[errorName].icon && icons[errors[errorName].icon] }
            <h2>{ errors[errorName].title }</h2>
            <p className="color-red"><strong>Kod błędu: { errorCode }</strong></p>
            { errors[errorName].description && <p dangerouslySetInnerHTML={{ __html: errors[errorName].description }}></p> }
            <Button onClick={ () => { dispatch(hideDrawer()) }} >{ errors[errorName].buttonText }</Button>
          </div>
        )
      case 'info':
        const infoName: string = ui.drawerData.name;
        return (
          <div className="drawer-inner drawer-info">
            <h2>{ infos[infoName].title }</h2>
            { infos[infoName].description && <p>{ infos[infoName].description }</p> }
            <Button onClick={ () => { dispatch(hideDrawer()) }} >{ infos[infoName].buttonText }</Button>
          </div>
        )
        case 'payout-info':
          return (
            <div className="drawer-inner  drawer-payout">
            <h2>Wypłata w drodze!</h2>
            <p className="desc">
            Wypłata jest obecnie procesowana przez Today Sp. z o.o.<br/>
<small>(Organizatora wykonawczego programu BAT Partners Hurt)</small>
            </p>
            <p className='desc'>Trafi na Twoje konto zgodnie z sesjami przychodzącymi w&nbsp;Twoim banku.</p>
            <Button color="white" onClick={ () => { dispatch(hideDrawer()) }} >Dzięki</Button>
          </div>
          )
      case 'device-register-info':
        return (
          <div className="drawer-inner drawer-info">
            <h2>Chcesz sam wpisać <br/>numer seryjny?</h2>
            <p>Pamiętaj, że przy wpisywaniu numeru seryjnego konieczne jest zachowanie dokumentu potwierdzającego sprzedaż.</p>
            <Button onClick={ () => { dispatch(hideDrawer()); navigate("/nowa-sprzedaz/numer-seryjny") }} >Tak, chcę wpisać</Button>
          </div>
        )
      case 'czechy2022':
        return (
          <div className="drawer-inner drawer-pop">
            <h2>Ahoj!</h2>
            <h3>Marka glo™ jest dostępna w wielu krajach<br/> Europy m.in. w Czechach.</h3>
            <p>Dlatego bez obaw sprzedawaj urządzenia glo™ naszym czeskim sąsiadom i zdobywaj kolejne nagrody pieniężne.</p>
            <Button onClick={ () => { dispatch(hideDrawer()) }} >Rozumiem</Button>
          </div>
        )
      case 'coupon-registered':
          return (
            <div className="drawer-inner drawer-info drawer-success">
              { icons['completed']}
              <h2>Kod rabatowy zarejestrowany</h2>
              <p>Możesz wydać konsumentowi nagrodę: <strong>2&nbsp;paczki neo™ w cenie jednej</strong></p>
              <Button onClick={ () => { dispatch(hideDrawer()) }} >Rozumiem</Button>
            </div>
          )
      case 'pit-info':
          return (
            <div className="drawer-inner drawer-info drawer-blue">
              <h2>Tylko krok dzieli Cię od wypłaty środków!</h2>
              <p>Pamiętaj, że pełne uzupełnienie profilu jest konieczne, aby bezproblemowo wypłacić środki po zakończeniu  aktywności! Twoje dane są niezbędne do prawidłowego rozliczenia podatkowego z uzyskanej nagrody.</p>
              <Button className={"white"} onClick={ () => { dispatch(hideDrawer()); navigate("/profil") }} >Uzupełnij dane</Button>
            </div>
          )
      case 'login-info':
          return (
            <div className="drawer-inner drawer-info drawer-blue">
              <h2>Zmiana w dostępie do wypłat</h2>
              <p>W dniu 1 listopada funkcja wypłat środków pieniężnych jest zablokowana. Ponowna możliwość wypłat będzie dostępna od 2 listopada w godzinach popołudniowych.</p>
              <Button className={"white"} onClick={ () => { dispatch(hideDrawer()); }} >Dzięki</Button>
            </div>
          )
          case 'missing-user-data':
            return (
              <div className="drawer-inner drawer-error">
        { icons['locked']}
              <h2>Odblokuj wypłaty</h2>
         <p>Uzupełnij numer konta i dane PIT w Profilu, aby odblokować możliwość odbierania środków.{" "} 
<strong>Pamiętaj, że jesteś odpowiedzialny za poprawność danych, które trafiają do urzędu.</strong>
</p>
              <Button onClick={ () => { dispatch(hideDrawer())
              navigate("/profil")
              }} >Przejdź do profilu </Button>
            </div>
            )
          
         
      default:
        return <div></div>
    }
  }

  return (
    <Modal
      isOpen={ ui.showDrawer } 
      contentLabel="Modal" 
      className={{
        base: 'drawer',
        afterOpen: 'drawer_after-open',
        beforeClose: 'drawer_before-close'
      }}
      overlayClassName={{
        base: 'overlay',
        afterOpen: 'overlay_after-open',
        beforeClose: 'overlay_before-close'
      }} 
      onRequestClose={ () => { dispatch(hideDrawer()) } } 
      closeTimeoutMS={ 500 }
    >
      <div className="swipe-close">
        { drawerContent() }
        <button className="btn-close" onClick={ () => { dispatch(hideDrawer()) } }><div className={ui.drawerType === "pit-info" || ui.drawerType === "payout-info" ? "icon white": "icon"}></div></button>
      </div>
    </Modal>
  )
}
