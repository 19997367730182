import React from "react";
import "./Button.scss";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "./img/arrow.svg";
interface Props {
  path?: string;
  color?: "white" | "blue" | "orange" | "grey";
  onClick?: () => void;
  children: any;
  className?: string;
  disabled?: boolean;
  type?:"submit" | "button" | "reset";
}

const Button: React.FC<Props> = ({ path, color = 'blue', onClick, children, className, disabled = false,type="submit" }) => {
  return !path ? (
    <button type={type} disabled={disabled} className={`Button ${color} ${className}`} onClick={onClick}><span>{ children }

    </span>    {!disabled && <span className="icon"><Arrow/></span>}</button>
  ) : (
    <Link className={`Button ${color} ${className}`} to={path || ''}><span>{ children }</span></Link>
  );
};

export default Button;
