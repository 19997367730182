import React, { useEffect } from 'react';
import PageLayout from "../../components/PageLayout/PageLayout";
import { SubmitHandler, useForm, FieldValues, FieldErrors } from 'react-hook-form';
import '../../theme/forms.scss';
import './Login.scss';
import { Link } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { useNavigate  } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../store/actions/user';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getAuthState } from '../../store/reducers/user';
import loginImgMobile from "./img/mobile.jpg";
import loginImgDesktop from "./img/desktop-img.jpg";

interface loginProps {
  emailAddress: 'string';
  password: 'string';
}

function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { register, setValue, handleSubmit, getValues, formState: { errors } } = useForm();
  const isAuthenticated = useAppSelector(getAuthState);

  useEffect(()=>{
    if (isAuthenticated) navigate("/dashboard", {replace: true})
  },[])

  const onSubmit = ({email, password}: FieldValues) => {
    dispatch(loginUser({ emailAddress: email, password: password}, navigate))
  }

  const onError = (errors: FieldErrors) => {
    //console.log(errors)
  }

  return (
    <PageLayout>
      <div className="page Login">
        <div className="login-image">
          <picture>
            <source media="(min-width: 1020px)" srcSet={loginImgMobile} />
            <img
              src={loginImgDesktop}
              alt={"Wejdź do programu Bat"}
              loading="lazy"
            />
          </picture>
        </div>
        <div className="body-form">
          <h1>Wejdź do programu BAT&nbsp;Partners HURT</h1>
          <form>
            <div className="input-item">
              <label htmlFor="email">E-mail</label>
              <input placeholder='jan@example.pl' className={ errors.email && 'error-input' } type="text" id="email" {...register("email", {required: true, pattern:/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})} ></input>
              {errors.email && errors.email.type === "pattern" && (
                <p className="error">Nieprawidłowa wartość</p>
              )}
              {errors.email && errors.email.type === "required" && (
                <p className="error">Pole wymagane</p>
              )}
            </div>
            <div className="input-item">
              <label htmlFor="password">Hasło</label>
              <input className={ errors.password && 'error-input' } type="password" id="password" {...register("password", {required: true})} ></input>
              {errors.password && errors.password.type === "pattern" && (
                <p className="error">Nieprawidłowa wartość</p>
              )}
              {errors.password && errors.password.type === "required" && (
                <p className="error">Pole wymagane</p>
              )}
            </div>
            <div className="text-center margin-20 text-smaller">
              Nie pamiętasz hasła? <Link to="/przypomnienie-hasla">Zresetuj hasło</Link>
            </div>
            <Button onClick={ handleSubmit(onSubmit, onError) }>Zaloguj</Button>
            <div className="text-center margin-20">
              Nie masz konta?<br/> 
              <Link to="/rejestracja">Zarejestruj się</Link>
            </div>
          </form>
        </div>
      </div>
    </PageLayout>
  );
}

export default LoginPage
