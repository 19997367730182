import React, { useEffect, useState } from "react";
import "./Nav.scss";
import { NavLink, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  useAnalyticsEventTracker,
  useAppDispatch,
  useAppSelector,
  useWindowDimensions,
} from "../../hooks";
import { getUI } from "../../store/reducers/ui";
import { ReactComponent as IconX } from "./img/icon-x.svg";
import { ReactComponent as IconArrow } from "./img/arrow-right.svg";
import { ReactComponent as IconChevronTop } from "./img/chevron-top.svg";
import { ReactComponent as IconChevronBot } from "./img/chevron-bot.svg";
import { closeMenu } from "../../store/actions/ui";
import { ReactComponent as Logo } from "./img/logo-white.svg";
import { useClickOutside } from "../../hooks";
import { pageView } from "../../helpers/gtag";
import { getUser } from "../../store/reducers/user";
import { logout } from "../../store/actions/user";

function Nav() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const ui = useAppSelector(getUI);
  const [selectedItem, setSelectedItem] = useState(0);
  const [subnavOpen, setSubnavOpen] = useState(false);
  const { height } = useWindowDimensions();
  const gaEventTracker = useAnalyticsEventTracker("Nav Event");
  let node = useClickOutside(() => closeNav());
  const user = useAppSelector(getUser);

  useEffect(() => {
    pageView(
      `${window.location.origin}${location.pathname}${location.search}`,
      location.pathname
    );
  }, [location]);

  const navItems = [
    {
      name: "Profil",
      link: "/profil",
    },
    {
      name: "Wypłać",
      link: "/wyplata",
    },
    {
      name: "Statystyki",
      link: "/statystyki",
    },
    {
      name: "Poznaj Produkty BAT",
      link: "/produkty-bat",
    },
    {
      name: "Kontakt",
      link: "/kontakt",
    },
    {
      name: "Regulaminy",
      link: "/regulaminy",
    },
  ];

  const toggleSubnav = (idx: number) => {
    setSubnavOpen(() => true);
    setSelectedItem(() => idx);
    if (idx === selectedItem) {
      setSubnavOpen(() => !subnavOpen);
    }
  };

  const closeNav = () => {
    dispatch(closeMenu());
    setSubnavOpen(false);
  };

  return (
    <div
      ref={node}
      className={ui.showMenu ? "nav active" : "nav"}
      style={{ height: height }}
    >
      <div className="nav-inner">
        <div className="logo">
          <Logo />
        </div>
        <div className="close" onClick={() => closeNav()}>
          <IconX />
        </div>
        <div className="nav-links">
          {navItems.map((item, key) => {
            return (
              <NavLink
                key={`nav-item-${key}`}
                className="nav-item"
                to={item.link}
                onClick={() => {
                  closeNav();
                  gaEventTracker("click subnav", item.name);
                }}
              >
                <span>{item.name}</span>
                <IconArrow />
              </NavLink>
            );
          })}
        </div>
        <div className="nav-footer">
          <span
            className="nav-item"
            onClick={() => {
              dispatch(logout(navigate));
              closeNav();
            }}
          >
            <span>Wyloguj</span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Nav;
