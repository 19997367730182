import { useForm } from "react-hook-form";
import Button from "../../../components/Button/Button";
import Countdown, { zeroPad } from "react-countdown";
import { useState } from "react";
import { sendRegisterOtp } from "../../../store/actions/user";
import { useAppDispatch } from "../../../hooks";
import { registerUser } from "../../../store/actions/user";
import Loading from "../../../components/Loading/LoadingScreen/Loading";
import {  useSelector } from "react-redux";
import {  getSubmitState } from "../../../store/reducers/user";
import "./OtpStep.scss";

type OtpStepType = {
  handleNextStep: () => void;
  handlePrevStep: () => void;
  phoneNumber: any;
  setFormValues: React.Dispatch<React.SetStateAction<undefined>>;
  formValues: any;
};

const OtpStep = ({
  formValues,
  setFormValues,
  handlePrevStep,
  phoneNumber,
}: OtpStepType) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const twoMinutesInMillisecounds = 2 * 60 * 1000 + Date.now();
  const [timer, setTimer] = useState(twoMinutesInMillisecounds);
  const [otpButton, setOtpButton] = useState(false);
  const dispatch = useAppDispatch();
  
  const submitting = useSelector(getSubmitState);

  const onSubmit = (data: any) => {
    let phone = formValues?.mobilePhone;
    if (phone?.indexOf("+48") === -1) {
      phone = "+48" + phone;
    }
    dispatch(
      registerUser({
        ...formValues,
        mobilePhone: phone,
        ...data,
      })
    );
  };

  const reSendOtp = () => {
    setOtpButton(false);
    setTimer(twoMinutesInMillisecounds);

    let phone = phoneNumber;
    if (phone.indexOf("+48") === -1) {
      phone = "+48" + phone;
    }

    dispatch(
      sendRegisterOtp({
        user: {
          mobilePhone: phone,
        },
      })
    );
  };

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      setOtpButton(true);
      return <p className="timer disabled">00:00</p>;
    } else {
      return (
        <div className="timer">
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </div>
      );
    }
  };

  return (
    <div className="OtpStep">
      <div className="form-step">
        <div className="tel-info">
          <p className="desc">
            Wysłaliśmy na Twój telefon
            <br /> SMS z kodem weryfikacyjnym.
          </p>
          <p className="number-label">Numer telefonu: </p>
          <p className="number">
            +48&nbsp;
            {phoneNumber && phoneNumber.match(/.{1,3}/g).join(" ")}
          </p>
        </div>
        <div>
          <div className="input-item">
            <label htmlFor="otp">Kod weryfikacyjny</label>
            <input
              placeholder="Wpisz otrzymany kod"
              className={errors?.otp && "error-input"}
              {...register("otp", { required: true })}
              defaultValue={""}
            />
            {errors.otp && errors?.otp?.type === "required" && (
              <p className="error">Uzupełnij pole</p>
            )}
          </div>
        </div>

        <div className="otp-container">
          <p>Nie dostałeś sms?</p>
          <div className="renew-wrap">
            <div>
              {otpButton ? (
                <div className="renew-otp" onClick={() => reSendOtp()}>
                  Wyślij kod ponownie
                </div>
              ) : (
                <div className="renew-otp disabled">
                  {" "}
                  Wyślij kod ponownie za{" "}
                </div>
              )}
            </div>

            <div className={otpButton ? "otp-counter hidden" : "otp-counter"}>
              <Countdown
                date={timer}
                key={timer}
                zeroPadTime={2}
                renderer={renderer}
              />
            </div>
          </div>
        </div>
        <div className="form-nav-buttons">
          {submitting ? (
               <Button>
               <Loading />
             </Button>
          ) : (
            <Button disabled={submitting} onClick={handleSubmit(onSubmit)} type="button">
            Dalej
          </Button>
          )}
        
          <Button onClick={handlePrevStep} type="button" color="white">
            Cofnij
          </Button>
        </div>
      </div>
    </div>
  );
};
export default OtpStep;
