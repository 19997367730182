import { useEffect, useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import { useAppDispatch } from '../../hooks';
import "./Consent.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateUser, logout } from '../../store/actions/user';
import { useForm, SubmitHandler, FieldValues, FieldErrors ,Controller} from 'react-hook-form';
import { getUser } from '../../store/reducers/user';
import { useNavigate, useParams } from "react-router-dom";
import Button from '../../components/Button/Button';

function Consent() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const user = useSelector(getUser);
  const { ident } = useParams()
  const { register, setValue, handleSubmit, getValues, reset, formState: { errors} } = useForm();
  const formValues = getValues()
  const [emailContact, setEmailContact] = useState(false)
  const [phoneContact, setPhoneContact] = useState(false)
  const [consent, setConsent] = useState(false)

  const onSubmit = (data: FieldValues) => {
    dispatch(updateUser({ user: {
      [`${ident}`]: consent,
      phoneContact: phoneContact,
      emailContact: emailContact,
      marketingConsent: phoneContact || emailContact ? true : false
    }}, true, navigate))
  }

  const onError = (errors: FieldErrors) => {
    //console.log(errors)
  }

  useEffect(() => {
    setEmailContact(user?.emailContact)
    setPhoneContact(user?.phoneContact)
  }, [user?.firstName])

  return (
    <PageLayout>
      <div className="page Consent">
        <div className="consent-inner">
          <h2>Cześć</h2>
          <p>Informujemy, że dokonaliśmy aktualizacji <a href="https://api.hurt.batpartners.pl/upload/2024-02-05/regulaminbphurt2024-65c0bd9ddc9a3.pdf" target="_blank" rel="noopener noreferrer">w&nbsp;Regulaminie programu BAT Partners Hurt</a>. Aktualizacja obejmuje: Wydłużenie okresu trwania programu do 31 grudnia 2024 roku (§ 2.2).</p>
          <div className="checkbox-item checkbox-item-consent">
            <div className="label-text" onClick={() =>{setConsent(!consent)}}>
              Akceptuję warunki Programu określone w Regulaminie Programu*
            </div>
            <input
              type="checkbox"
              id="consent"
              defaultChecked={false}
            />
            <label onClick={() =>{setConsent(!consent)}}
              htmlFor={"consent"}
              className={consent ? "check active" : "check"}
            >
              {consent ? "Tak" : "Nie"}
            </label>
          </div>
          <p>Wyrażam zgodę na przetwarzanie moich danych osobowych przez British American Tobacco Polska Trading sp. z o.o. z&nbsp;siedzibą w&nbsp;Warszawie (BAT) w celu przesyłania mi informacji na temat produktów i&nbsp;usług oferowanych przez BAT.</p>
          <p>Jako formę kontaktu wybieram:</p>
          <div className="checkbox-item checkbox-item-marketing">
            <div className="label-text" onClick={() =>{setEmailContact(!emailContact)}}>
              wiadomości przesyłane drogą elektroniczną (e-mail, SMS, serwisy internetowe, okienka pop up)
            </div>
            <input
              type="checkbox"
              id="emailContact"
              defaultChecked={user?.emailContact}
            />
            <label onClick={() =>{setEmailContact(!emailContact)}}
              htmlFor={"emailContact"}
              className={emailContact ? "check active" : "check"}
            >
              {emailContact ? "Tak" : "Nie"}
            </label>
          </div>
          <div className="checkbox-item checkbox-item-marketing">
            <div className="label-text" onClick={() =>setPhoneContact(!phoneContact)}>
              połączenia głosowe (rozmowy telefoniczne, komunikaty IVR)
            </div>
            <input
              type="checkbox"
              id="phoneContact" 
              defaultChecked={user?.phoneContact}
            />
            <label onClick={() =>setPhoneContact(!phoneContact)}
              htmlFor={"phoneContact"}
              className={phoneContact ? "check active" : "check"}
            >
              {phoneContact ? "Tak" : "Nie"}
            </label>
          </div>
          <p>* Zgoda konieczna do wzięcia udziału w Programie. <br/><br/>Wycofanie zgody nie będzie miało jednak wpływu na zgodność z&nbsp;prawem naszych działań podjętych jeszcze przed jej wycofaniem.Więcej informacji o&nbsp;tym, jakie dane zbieramy, w&nbsp;jakim celu oraz do czego je wykorzystujemy znajdziesz <a href="https://www.hurt.batpartners.pl/pdf/klauzula_informacy_na_bp_hurt.pdf" target="_blank" rel="noopener noreferrer">w&nbsp;Klauzuli Informacyjnej</a>.</p>
          <div className="btn-group">
            <Button onClick={ handleSubmit(onSubmit, onError) } disabled={!consent}>Zapisz</Button>
            <span className="logout" onClick={() => {
              dispatch(logout(navigate));
            }}>Wyloguj się</span>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default Consent;
