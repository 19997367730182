import React, { useState, useEffect } from "react";

import PageLayout from "../../../components/PageLayout/PageLayout";

import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import "./NewSaleSerialNumber.scss";
import { FieldErrors, FieldValues, useForm } from "react-hook-form";
import { registerVisit } from "../../../store/actions/activations";
import { useDispatch, useSelector } from "react-redux";
import { getRegisterProgress } from "../../../store/reducers/activations";
import { useAppDispatch } from "../../../hooks";
import { API_URL, appFetch } from "../../../helpers/fetch";
import { useAppSelector } from "../../../hooks";

import KentBlue from "./img/kent_blue.png";
import KentRed from "./img/kent_red.png";
import KentBlue20 from "./img/kent_blue_20.png";
import KentRed20 from "./img/kent_red_20.png";

import checked from "./img/yes.svg";
import unchecked from "./img/no.svg";
import { getToken } from "../../../store/reducers/user";

import { useWindowDimensions } from "../../../hooks";
import Title from "../../../components/Title/Title";

function NewSaleSerialNumber() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const registerInProgress = useSelector(getRegisterProgress);
  const december = new Date() > new Date("2023-12-01T23:59:00.000");
  const [inputState, setInputState] = useState("");
  const [step, setStep] = useState(1);
  const [set1, setSet1] = useState(false);
  const [set2, setSet2] = useState(false);
  const [availableSets, setAvailableSets] = useState([]);
  const [availableSet1, setAvailableSet1] = useState(false);
  const [availableSet2, setAvailableSet2] = useState(false);

  const token = useSelector(getToken);

  const { width, height } = useWindowDimensions();

  // useEffect(() => {
  //   appFetch(
  //     "POST",
  //     API_URL,
  //     "/visit/product-set",
  //     {},
  //     token?.uuid ? token?.uuid : false,
  //     (result: any) => {
  //       if (result.status && result.status.success) {
  //         // setNewsItem(result.data);
  //         console.log("💀💀💀💀", result.data);
  //         setAvailableSets(result.data);
  //         console.log("💀", Object.keys(result.data));
  //         if (Object.keys(result.data).includes("zestaw_1")) {
  //           setAvailableSet1(true);
  //         }
  //         if (Object.keys(result.data).includes("zestaw_2")) {
  //           setAvailableSet2(true);
  //         }
  //       } else {
  //       }
  //     },
  //     () => {}
  //   );
  // }, [token]);

  const successCallback = () => {
    setStep(1);
    navigate("/rejestracja-wizyty/success");
  }

  const onSubmit = ({ serial }: FieldValues) => {
    let sendSets: any = [];
    if (set1 && availableSet1) {
      // @ts-ignore
      sendSets = ["zestaw_1", ...sendSets];
    }
    if (set2 && availableSet2) {
      // @ts-ignore
      sendSets = [...sendSets, "zestaw_2"];
    }

    console.log("👨‍🏭👨‍🏭👨‍🏭👨‍🏭", serial, sendSets);

    if (!registerInProgress) {
      
      dispatch(registerVisit(serial, successCallback, sendSets));
    }
  };

  const onError = (errors: FieldErrors) => {
    //console.log(errors)
  };
  // @ts-ignore
  const getData = async (payload) => {
    appFetch(
      "POST",
      API_URL,
      "/visit/product-set",
      {
        visit: {
          sapId: payload,
        },
      },
      token?.uuid ? token?.uuid : false,
      (result: any) => {
        if (result.status && result.status.success) {
          // setNewsItem(result.data);
          console.log("💀💀💀💀", result.data);
          setAvailableSets(result.data);
          console.log("💀", Object.keys(result.data));
          if (Object.keys(result.data).includes("zestaw_1")) {
            setAvailableSet1(true);
          }
          if (Object.keys(result.data).includes("zestaw_2")) {
            setAvailableSet2(true);
          }
          setStep(step + 1)
        } else {
        }
      },
      () => {}
    );
    console.log(payload);
  };

  return (
    <PageLayout>
      <div className="page NewSaleSerialNumber">
        <div className="heading">
          {/* <h2 className="title">Rejestracja wizyty</h2> */}
          <Title>Rejestracja wizyty</Title>
        </div>
        <div className="device-wrapper">
          <div className="step-wrapper">
            <span className={step >= 1 ? "step active" : "step"}>1</span>
            <span className={step >= 2 ? "step active" : "step"}>2</span>
          </div>
          {step === 1 ? (
            <>
              <h3>
                Wpisz numer <br /> odwiedzonego punktu
              </h3>
              {/* {...register("serial", {required: true, minLength: 1, maxLength: 64})} */}
              <div className="input-item">
                <label htmlFor="serial">
                  Numer Fizyczny Punktu
                  <br />
                  <span>(Kod otrzymasz od przedstawiciela)</span>
                </label>
                <input
                  className={errors.serial && "error-input"}
                  type="text"
                  id="serial"
                  {...register("serial", {
                    required: true,
                    minLength: 1,
                    maxLength: 64,
                  })}
                  placeholder="AJ845076"
                  onChange={(e) => setInputState(e.target.value)}
                ></input>
                {errors.serial && errors.serial.type === "minLength" && (
                  <p className="error">Nieprawidłowa wartość</p>
                )}
                {errors.serial && errors.serial.type === "required" && (
                  <p className="error">Wpisz numer seryjny produktu</p>
                )}
                {errors.serial && errors.serial.type === "maxLength" && (
                  <p className="error">Maksymalnie 64 znaki</p>
                )}
              </div>
              <Button
                disabled={inputState.length == 0}
                color={inputState.length == 0 ? "grey" : "blue"}
                onClick={() => {
                  // setStep(step + 1);
                  // @ts-ignore
                  getData(inputState);
                }}
              >
                Dalej
              </Button>
            </>
          ) : (
            <>
              <h3>Wybierz sprzedane zestawy</h3>
              {/* {...register("serial", {required: true, minLength: 1, maxLength: 64})} */}
              <div className="input-item checkboxs-wrapper">
                <div
                  className={set1 || !availableSet1 ? "set checked" : "set"}
                  style={availableSet1 ? {} : { opacity: "0.5" }}
                  onClick={
                    availableSet1
                      ? () => setSet1(!set1)
                      : (e) => e.preventDefault()
                  }
                >
                  <div className="title-wrapper">
                    <h4>
                      Zestaw 1 <span>Nagroda: 30 zł</span>
                    </h4>
                    <img
                      src={set1 || !availableSet1 ? checked : unchecked}
                    ></img>
                  </div>
                  <div className="sets-wrapper">
                    <div className="set-wrapper">
                      <img src={KentBlue} alt="KENT BLUE 100’s " />
                      <div className="set-heading-wrapper">
                        <strong>KENT BLUE 100’s </strong>
                        <p>min. 1 opakowanie zbiorcze (pakiet)</p>
                      </div>
                    </div>
                    <div className="set-wrapper">
                      <img src={KentRed} alt="KENT RED 100’s" />
                      <div className="set-heading-wrapper">
                        <strong>KENT RED 100’s</strong>
                        <p>min. 1 opakowanie zbiorcze (pakiet)</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={set2 || !availableSet2 ? "set checked" : "set"}
                  style={availableSet2 ? {} : { opacity: "0.5" }}
                  onClick={
                    availableSet2
                      ? () => setSet2(!set2)
                      : (e) => e.preventDefault()
                  }
                >
                  <div className="title-wrapper">
                    <h4>
                      Zestaw 2 <span>Nagroda: 30 zł</span>
                    </h4>
                    <img
                      src={set2 || !availableSet2 ? checked : unchecked}
                    ></img>
                  </div>
                  <div className="sets-wrapper">
                    <div className="set-wrapper">
                      <img src={KentBlue20} alt="PALL MALL FLOW BLUE 20 KS" />
                      <div className="set-heading-wrapper">
                        <strong>PALL MALL FLOW BLUE 20 KS </strong>
                        <p>min. 1 opakowanie zbiorcze (pakiet)</p>
                      </div>
                    </div>
                    <div className="set-wrapper">
                      <img src={KentRed20} alt="PALL MALL FLOW RED 20 KS" />
                      <div className="set-heading-wrapper">
                        <strong>PALL MALL FLOW RED 20 KS</strong>
                        <p>min. 1 opakowanie zbiorcze (pakiet)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cta-group">
                <Button onClick={() => setStep(1)} color="white">
                  Cofnij

                </Button>
              <Button
                disabled={!set1 && !set2}
                color={!set1 && !set2 ? "grey" : "blue"}
                onClick={handleSubmit(onSubmit, onError)}
              >
                Otrzymaj nagrodę
              </Button>
              </div>
            
            </>
          )}
        </div>
      </div>
    </PageLayout>
  );
}

export default NewSaleSerialNumber;
