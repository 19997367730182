import React, { useEffect, useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import Title from "../../components/Title/Title";
import { getUser } from "../../store/reducers/user";
import { showDrawer } from "../../store/actions/ui";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { sendOtpPayoutRequest } from "../../store/actions/account";
import Select from "react-select";
import Button from "../../components/Button/Button";
import "./Payout.scss";
import { useDispatch, useSelector } from "react-redux";
import { accountPaymentHistory, visitStats } from "../../store/actions/account";
import {
  getAccountBalance,
  getPayoutHistory,
  getVisitStats,
  getPayoutOtp,
} from "../../store/reducers/account";
import moment from "moment";

import ButtonTab from "../../components/ButtonTab/ButtonTab";

function Payout() {
  const user = useAppSelector(getUser);
  const balance = useAppSelector(getAccountBalance);
  // const profit = useAppSelector(getAccountProfit);
  const dispatch = useAppDispatch();
  const payoutHistory = useSelector(getPayoutHistory);
  const [missingUserData, setMissingUserData] = useState(false);
  const [transactionListType, setTransactionListType] = useState("income");
  const visits = useAppSelector(getVisitStats);
  const monthList = visits?.periods
    ? Object.values(visits.periods).map((month: any) => month)
    : [];
  const monthIdentList = monthList?.map((month: any) => month?.ident);
  const [currentMonthId, setCurrentMonthId] = useState(null);
  const currentMonth = monthList.filter(
    (item) => item.ident === currentMonthId
  );
  const showSelect =
    transactionListType === "income" && monthIdentList.length > 0;
  const navigate = useNavigate();
  const payoutOtp = useSelector(getPayoutOtp);

  useEffect(() => {
    if (user?.firstName) {
      setCurrentMonthId(visits?.currentPeriod?.ident);
    }
  }, [visits?.currentPeriod?.ident, user?.firstName]);

  useEffect(() => {
    if (user?.firstName) {
      if (
        user?.dob != null &&
        user?.streetAddress != null &&
        user?.postalCode != null &&
        user?.cityName != null &&
        user?.taxOfficeName != null &&
        user?.iban != null
      ) {
        setMissingUserData(false);
      } else {
        setMissingUserData(true);
      }
    }
  }, [user?.dob]);

  useEffect(() => {
    dispatch(accountPaymentHistory());
    dispatch(visitStats());

    // if (moment().isAfter('01.29.2023 00:00:01') && moment().isBefore('02.06.2023 23:59:59')) {
    //   setDateDisabled(true)
    // }
  }, []);

  return (
    <PageLayout withBottom>
      <div className="page Payout">
        <Title>Wypłać</Title>
        <div className="earnings-wrap">
          <div
            className={
              missingUserData
                ? "earnings-box dropshadow-box missing-user-data"
                : "earnings-box dropshadow-box"
            }
          >
            <div className="earnings-top">
              {/* <div className="earnings-total">
                <h3 className="earnings-title">Twoje zarobki</h3>
                <div
                  className={
                    profit > 999
                      ? "earnings-total-number smaller"
                      : "earnings-total-number"
                  }
                >
                  {profit}
                  <span> zł</span>
                </div>
                <div className="sub-text">w tym sezonie</div>
              </div> */}
              <div className="earnings-current">
                <div className="columns">
                  <div className="title-column">
                    <div className="sub-text">Twoje dostępne saldo:</div>
                  </div>
                </div>
                <div className="columns">
                  <div
                    className={
                      balance > 999
                        ? "earnings-current-number smaller"
                        : "earnings-current-number"
                    }
                  >
                    {balance}
                    <span> zł</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="earnings-bot">
              <>
                {/* className={
                      !(balance > 0) || user?.blacklisted || payoutInProgress
                        ? "disabled"
                        : ""
                    } */}
                {missingUserData ? (
                  <Button
                    onClick={() => dispatch(showDrawer("missing-user-data"))}
                  >
                    Wypłać
                  </Button>
                ) : (
                  <Button
                    className={
                      !(balance > 0) || user?.blacklisted ? "disabled" : ""
                    }
                    disabled={(!(balance > 0) || user?.blacklisted || payoutOtp?.processed)}
                    onClick={() => {
                      dispatch(
                        sendOtpPayoutRequest(navigate("/wyplata/podsumowanie"))
                      );
                    }}
                  >
                    Wypłać
                  </Button>
                )}
                <p className="desc">
                  Minimalna wypłata:<strong> 1 zł</strong>
                  {/* <br /> Maksymalna wypłata: <strong>199 zł</strong> */}
                </p>
              </>
            </div>
          </div>
          <div className="Transactions">
            <div className="transactions-header">
              {" "}
              <div className="button-tab-wrapper">
                <ButtonTab
                  active={transactionListType === "income"}
                  onClick={() => setTransactionListType("income")}
                >
                  Przychody
                </ButtonTab>
                <ButtonTab
                  active={transactionListType === "payout"}
                  onClick={() => setTransactionListType("payout")}
                >
                  Wypłaty
                </ButtonTab>
              </div>
              {showSelect && (
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isSearchable={false}
                  onChange={(e: any) => setCurrentMonthId(e.value)}
                  defaultValue={{
                    value: currentMonthId,
                    label: currentMonthId,
                  }} // Ustawienie domyślnej wartości
                  value={{ value: currentMonthId, label: currentMonthId }}
                  options={monthIdentList?.map((month) => ({
                    value: month,
                    label: month,
                  }))}
                />
              )}
            </div>
            {transactionListType === "income" && (
              <div className="income">
                {currentMonth?.map((month, index) => (
                  <div key={index} className="summary">
                    <p className="copy">Zebrana kwota:</p>
                    <div className="value">
                      {month?.verified ? (
                        <span className="confirmed">{month?.payout} zł</span>
                      ) : (
                        <>
                          <span className="theoretical">
                            {month?.payout} zł*
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                ))}

                {/* <ul className="income-list">
                {currentMonth?.map((item) => (
    <li className="list-item" key={item.id}>
      <span className="task">Główne zadanie</span>
      <div className="value">
        {item.verified && (
<>
<span className="confirmed">{item.payout} zł</span>
        <img
          className="icon"
          src={approvedIcon}
          alt="zatwierdzona"
        /></>
        )}
      
      {!item.verified && 
      
        <span className="theoretical">{item.payout} zł*</span>
      }
      </div>
    </li>
  ))}
                
                </ul> */}
                <p className="disclaimer">
                  <strong>
                    {" "}
                    *Wyświetlona kwota jest przybliżona. Czekamy na jej
                    potwierdzenie.{" "}
                  </strong>
                  Finalna wysokość nagrody oraz dodanie jej do salda pojawi się
                  po pozytywnej weryfikacji punktów.
                </p>
              </div>
            )}
            {transactionListType === "payout" && (
              <div className="payout">
                <div className="stats-wrapper">
                  <table>
                    <tbody>
                      {payoutHistory.length > 0 ? (
                        <table>
                          <tbody>
                            {payoutHistory.map((item: any, key: any) => {
                              return (
                                <tr key={key}>
                                  <td className="date">
                                    {moment(item.created.date).format(
                                      "DD.MM.YYYY"
                                    )}
                                  </td>
                                  <td className="name">
                                    {/* Tu wstaw odpowiednie dane */}
                                  </td>
                                  <td className="score">{`${Math.abs(
                                    item.value
                                  )} zł`}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <p className="empty">
                          Nie dokonałeś(-aś) jeszcze żadnych wypłat.
                        </p>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default Payout;
