import React, { useEffect, useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./Stats.scss";
import Title from "../../components/Title/Title";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { romanize, useAppDispatch } from "../../hooks";
import { listPrizes, listStats } from "../../store/actions/rankings";
import { getVisitHistory } from "../../store/reducers/rankings";
import { ReactComponent as ShowMoreIcon } from "./img/show-more.svg";
const Stats = () => {
  const visitHistory = useSelector(getVisitHistory);
  const dispatch = useAppDispatch();
  const [showMore, setShowMore] = useState(false);
  const showButton = visitHistory.length > 10 && !showMore;
  const filteredVisitHistory = showMore
    ? visitHistory
    : visitHistory.slice(0, 10);

  useEffect(() => {
    dispatch(listStats());
  }, []);

  const handlePageChange = (event: any) => {
    //dispatch(balanceHistory((event.selected * 100) % visitHistoryCount))
  };

  return (
    <PageLayout withBottom>
      <section className="page Stats">
        <Title>Moje statystyki</Title>
      </section>
      <div className="stats-bottom-section">
        <div className="history-section">
          <div className="stats-wrapper">
            <h3>Raport wizyt</h3>
            <div className="table-wrapper">
              <div className="table-header">
                <span> Data </span>
                <span> Numer punktu sprzedażowego </span>
              </div>
              <table>
                <tbody>
                  {filteredVisitHistory.map((item: any, key: any) => {
                    return (
                      <tr key={key}>
                        <td className="date">
                          {moment(item?.created?.date).format("DD.MM.YYYY")}
                        </td>
                        <td className="name">{item?.sapId}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {showButton && (
                <button
                  onClick={() => setShowMore(true)}
                  className="show-more-cta"
                >
                  Pokaż więcej <ShowMoreIcon />
                </button>
              )}
            </div>
            {/*visitHistoryCount <= 100 ? null :
              <div className="pagination">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageChange}
                  pageRangeDisplayed={5}
                  pageCount={visitHistoryCount / 100}
                  previousLabel="<"
                />
              </div>
              */}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Stats;
