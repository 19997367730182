import PageLayout from "../../components/PageLayout/PageLayout";
import "./PayoutSuccess.scss";
import Button from "../../components/Button/Button";
import mobileImg from "./img/mobile.png"
import desktopImg from "./img/desktop.png"



function PayoutSuccess() {



  return (
    <PageLayout >
      <div className="page PayoutSuccess">
      <div className="image">
          <picture>
            <source media="(min-width: 1020px)" srcSet={desktopImg} />
            <img
              src={mobileImg}
              alt={"Wejdź do programu Bat"}
              loading="lazy"
            />
          </picture>
        </div>
      <div className="body">
        <h1 className="title">Udało się!</h1>
        <div className="content">
         
          <p>
         <strong>Wypłata jest procesowana przez Today Sp. z o.o.</strong>
         <small>(Organizatora wykonawczego programu BAT&nbsp;Partners&nbsp;Hurt)</small>
          </p>
          <p>
          <strong>Trafi na Twoje konto zgodnie z sesjami przychodzącymi w&nbsp;Twoim banku.</strong>
          </p>
      
         
        </div>
        <div className="btn-group">
        <Button color="white" path="/wyplata">Nowa wypłata</Button>
        <Button path="/dashboard">Strona główna</Button>
        </div>
      </div>
      </div>
    </PageLayout>
  );
}

export default PayoutSuccess;
