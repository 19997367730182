import PageLayout from "../../../components/PageLayout/PageLayout";
import "./NewSaleDetails.scss";
import gloImg from "./img/SAPimg.jpg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
function NewSaleDetails() {
  const navigate = useNavigate();

  return (
    <PageLayout>
      <div className="page NewSaleDetails">
        <div className="card">
          <div className="photo">
          <img src={ gloImg } alt="" />
          </div>
        </div>
        <div className="info">
          <div className="footer dropshadow-box">
            <h3 className="title">
              Wizyta została
              poprawnie dodana do Twojego konta
            </h3>
          </div>
            <Link to="/dashboard" className="cta">Wróć na stronę główną</Link>
        </div>
      </div>
    </PageLayout>
  );
}

export default NewSaleDetails;
