import React, { useState } from "react";
import { useLocation, NavLink, Link } from "react-router-dom";
import {
  useWindowDimensions,
  useScrollPosition,
  useAppDispatch,
  useAppSelector,
} from "../../hooks";
import { getUser } from "../../store/reducers/user";
import { ReactComponent as IconMenu } from "./img/icon-menu.svg";
import { ReactComponent as IconBell } from "./img/icon-bell.svg";
import { ReactComponent as Logo } from "./img/logo.svg";
import ButtonBack from "../Button/ButtonBack/ButtonBack";
import "./Header.scss";
import { showMenu } from "../../store/actions/ui";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBellNoti } from "../../store/reducers/notifications";
import { getUI } from "../../store/reducers/ui";
import { ReactComponent as ColorLine } from "../../../img/kreski.svg";
import OutageInfo from "./OutageInfo";
import ContestInfo from "./ContestInfo";

interface Props {
  castrated?: boolean;
}

function Header({ castrated }: Props) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { width, height } = useWindowDimensions();
  const navigate = useNavigate();
  const noti = useSelector(getBellNoti);
  const ui = useSelector(getUI);
  const scrollPosition = useScrollPosition();
  const changeHeaderToFixed = scrollPosition > 28 && width < 1270;
  const user = useAppSelector(getUser);
  const [outageInfoViewed, setOutageInfo] = useState(
    localStorage.getItem("outageInfo04052023Viewed")
  );

  const messageNumber = () => 0;
  return (
    <>
      {/* !castrated && <div className="header-info">GLO New Heating Technology.<br/> <span>|</span> <strong>Wkłady tytoniowe NEO dedykowane do GLO New Heating Technology.</strong></div> */}
      <header className={changeHeaderToFixed ? "Header fixed" : "Header"}>
        {castrated ? (
          <>
            <ColorLine className="color-line" preserveAspectRatio="none" />
            <div className="header-inner">
              <div className="logo">
                <Link to={user?.firstName ? "/dashboard" : "/"}>
                  <Logo />
                </Link>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* <div className="header-info">GLO New Heating Technology.<br/> <span>|</span> <strong>Wkłady tytoniowe NEO dedykowane do GLO New Heating Technology.</strong></div> */}
            <ColorLine className="color-line" preserveAspectRatio="none" />
            <div className="header-inner">
              <div
                className="nav-trigger"
                onClick={() => {
                  dispatch(showMenu());
                }}
              >
                <IconMenu />
              </div>
              <div className="logo">
                <Link to={user?.firstName ? "/dashboard" : "/"}>
                  <Logo />
                </Link>
              </div>
              {/* { width < 1270 ?
                location.pathname != '/dashboard' ? 
                <ButtonBack/> : <div className="logo"><Logo/></div>
              : <div className="logo"><Link to="/dashboard"><Logo role="link"/></Link></div>
            } */}
            </div>
            {/*outageInfoViewed === 'true' ? null : <OutageInfo hide={()=>setOutageInfo('true')} />*/}
            {/*ui.contestInfo === 'true' || ui.contestInfo === true ? null : <ContestInfo />*/}
          </>
        )}
      </header>
    </>
  );
}

export default Header;
