import React, { useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./ShortcutPage.scss";
import ButtonTab from "../../components/ButtonTab/ButtonTab";

import { ReactComponent as IconCircle } from "./img/circle.svg";
import { ReactComponent as IconChrome } from "./img/ic-chrome.svg";
import { ReactComponent as IconSafari } from "./img/ic-safari.svg";
import { ReactComponent as IconAdd } from "./img/ic-add.svg";
import { ReactComponent as IconShare } from "./img/ic-share.svg";
import { ReactComponent as IconAddDroid } from "./img/ic-add_a.svg";
import { ReactComponent as IconDots } from "./img/ic-dots.svg";

function About() {
  const [index, setIndex] = useState(0);
  return (
    <PageLayout>
      <div className="ShortcutPage">
        <div className="wrapper">
          <h1 className="title">Dodawanie skrótu na&nbsp;pulpit</h1>
          <p className="desc">
            Sprawdź jak ułatwić sobie życie dodając ikonę BAT Partners HURT
            bezpośrednio na ekran początkowy swojego telefonu.
          </p>
          <ul className="indexed-nav">
            <ButtonTab onClick={() => setIndex(0)} active={index === 0}>
              Android
            </ButtonTab>
            <ButtonTab onClick={() => setIndex(1)} active={index === 1}>
              iOS
            </ButtonTab>
          </ul>
        </div>
        {index === 0 ? (
          <Swiper
            slidesPerView={1}
            centeredSlides={false}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
          >
            <ul className="cards">
              <SwiperSlide>
                <div className="card">
                  <div className="nr">
                    <IconCircle />
                    <span>1</span>
                  </div>
                  <div className="card-inner">
                    <p>
                      <strong>
                        Otwórz stronę hurt.batpartners.pl w przeglądarce Google
                        Chromena swoim telefonie.
                      </strong>
                    </p>
                    <p>
                      Ten krok masz już za sobą, jeśli właśnie używasz Google
                      Chrome!
                    </p>
                  </div>
                  <IconChrome />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card">
                  <div className="nr">
                    <IconCircle />
                    <span>2</span>
                  </div>
                  <div className="card-inner">
                    <p>
                      <strong>Otwórz Opcje</strong>
                    </p>
                    <p>
                      Żeby to zrobić wybierz ikonę trzech kropek w prawym dolnym
                      rogu.
                    </p>
                  </div>
                  <IconDots />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card">
                  <div className="nr">
                    <IconCircle />
                    <span>3</span>
                  </div>
                  <div className="card-inner">
                    <p>
                      <strong>
                        Z listy dostępnych opcji wybierz „Dodaj do ekranu
                        głównego”
                      </strong>
                    </p>
                  </div>
                  <IconAddDroid />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card">
                  <div className="nr">
                    <IconCircle />
                    <span>4</span>
                  </div>
                  <div className="card-inner">
                    <p>
                      <strong>
                        Postępuj zgodnie z&nbsp;instrukcjami sugerowanymi przez
                        Google Chrome
                      </strong>
                    </p>
                  </div>
                  <IconChrome />
                </div>
              </SwiperSlide>
            </ul>
          </Swiper>
        ) : null}
        {index === 1 ? (
          <Swiper
            slidesPerView={1}
            centeredSlides={true}
            spaceBetween={15}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
          >
            <ul className="cards">
              <SwiperSlide>
                <div className="card">
                  <div className="nr">
                    <IconCircle />
                    <span>1</span>
                  </div>
                  <div className="card-inner">
                    <p>
                      <strong>
                        Otwórz stronę hurt.batpartners.pl w przeglądarce Safarina
                        swoim telefonie.
                      </strong>
                    </p>
                    <p>
                      Ten krok masz już za sobą, jeśli właśnie używasz Safari!
                    </p>
                  </div>
                  <IconSafari />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card">
                  <div className="nr">
                    <IconCircle />
                    <span>2</span>
                  </div>
                  <div className="card-inner">
                    <p>
                      <strong>Wybierz ikonę udostępniania</strong>
                    </p>
                    <p>Jest ona widoczna na środku dolnego paska nawigacji.</p>
                  </div>
                  <IconShare />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card">
                  <div className="nr">
                    <IconCircle />
                    <span>3</span>
                  </div>
                  <div className="card-inner">
                    <p>
                      <strong>Wybierz „Do ekranu początkowego”</strong>
                    </p>
                    <p>
                      Zobaczysz tę opcję, jeśli wysuniecz do góry okno, które
                      pojawiło sięna dole ekranu.
                    </p>
                  </div>
                  <IconAdd />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card">
                  <div className="nr">
                    <IconCircle />
                    <span>4</span>
                  </div>
                  <div className="card-inner">
                    <p>
                      <strong>Potwierdź klikając „Dodaj”</strong>
                    </p>
                    <p>Link znajduje się w prawym górnym rogu</p>
                  </div>
                  <IconSafari />
                </div>
              </SwiperSlide>
            </ul>
          </Swiper>
        ) : null}
      </div>
    </PageLayout>
  );
}

export default About;
