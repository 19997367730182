import PageLayout from "../../components/PageLayout/PageLayout";
import Title from "../../components/Title/Title";
import { getUser } from "../../store/reducers/user";
import {
  useAppSelector,
  formatPhoneNumber,
  useAppDispatch,
  useAnalyticsEventTracker,
} from "../../hooks";
import moneyIcon from "./img/money.svg";
import { useNavigate } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";
import "./PayoutSummary.scss";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../components/Button/Button";
import { formatDate } from "../../hooks";
import {
  getAccountBalance,
  getPayoutInProgress,
} from "../../store/reducers/account";
import { sendOtpPayoutRequest, payout } from "../../store/actions/account";

const dateStringInMillisecounds = (dateString: string | null): number => {
  if (dateString !== null) {
    const milliseconds = Date.parse(dateString);
    return milliseconds;
  } else {
    return 2 * 60 * 1000 + Date.now();
  }
};

function PayoutSummary() {
  const gaEventTracker = useAnalyticsEventTracker("Payout Event");
  const payoutInProgress = useSelector(getPayoutInProgress);
  const user = useAppSelector(getUser);
  const balance = useAppSelector(getAccountBalance);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [timer, setTimer] = useState(() =>
    dateStringInMillisecounds(sessionStorage.getItem("otpDate"))
  );
  const [otpButton, setOtpButton] = useState(false);

  const now = new Date().toISOString();
  const navigate = useNavigate();

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      setOtpButton(true);
      return <p className="timer disabled">00:00</p>;
    } else {
      return (
        <div className="timer">
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </div>
      );
    }
  };
  const onSubmit = (data: any) => {
    const payload = {
      user: {
        otp: data.otp,
      },
    };
    dispatch(
      payout(payload, () => {
        navigate("/wyplata/zakonczona");
      })
    );
    gaEventTracker("click", "Payout Button");
  };

  const formatIban = (iban: string) => {
    if (iban) {
      return iban.replace(/(.{4})/g, "$1 ").replace("PL", "");
    } else {
      return "";
    }
  };

  const transferData = {
    title: `Wypłata - BAT Partners Hurt ${formatDate(now, {
      month: "2-digit",
      year: "2-digit",
      day: "2-digit",
    })}`,
    mobilePhone: formatPhoneNumber(user?.mobilePhone),
    iban: formatIban(user?.iban),
    fullName: `${user.firstName ?? ""} ${user.lastName ?? ""}`,
    payoutValue: balance,
  };

  return (
    <PageLayout withBottom>
      <div className="page PayoutSummary">
        <Title>Wypłata środków</Title>
        <div className="payout">
          <div className="payout-details dropshadow">
            <div className="payout-header">
              <img src={moneyIcon} alt="Pieniądz" />
              <span className="value">{transferData?.payoutValue} zł</span>
            </div>
            <div className="user-info">
              <div className="item">
                <div className="label">Odbiorca:</div>
                <div className="value">{transferData?.fullName}</div>
              </div>
              <div className="item">
                <div className="label">Numer Konta:</div>
                <div className="value">{transferData?.iban}</div>
              </div>
              <div className="item">
                <div className="label">Tytuł:</div>
                <div className="value">{transferData?.title}</div>
              </div>

              <div className="item">
                <div className="label">Numer Telefonu:</div>
                <div className="value big">{transferData?.mobilePhone}</div>
              </div>
            </div>
          </div>
          <div className="payout-otp">
            <p className="desc">
              <strong>
                Sprawdź dane i potwierdź transakcję wpisując kod weryfikacyjny
              </strong>{" "}
              (wysłany w wiadomości SMS).
            </p>
            <div>
              <div className="input-item">
                <label htmlFor="otp">Kod weryfikacyjny</label>
                <input
                  placeholder="Wpisz otrzymany kod"
                  className={errors?.otp && "error-input"}
                  {...register("otp", { required: true })}
                  defaultValue={""}
                />
                {errors.otp && errors?.otp?.type === "required" && (
                  <p className="error">Uzupełnij pole</p>
                )}
              </div>
            </div>

            <div className="otp-container">
              <p>Nie dostałeś sms?</p>
              <div className="renew-wrap">
                <div>
                  {otpButton ? (
                    <div
                      className="renew-otp"
                      onClick={() => dispatch(sendOtpPayoutRequest)}
                    >
                      Wyślij kod ponownie
                    </div>
                  ) : (
                    <div className="renew-otp disabled">
                      {" "}
                      Wyślij kod ponownie za{" "}
                    </div>
                  )}
                </div>

                <div
                  className={otpButton ? "otp-counter hidden" : "otp-counter"}
                >
                  <Countdown
                    date={timer}
                    key={timer}
                    zeroPadTime={2}
                    renderer={renderer}
                  />
                </div>
              </div>
            </div>
            <Button
              disabled={payoutInProgress}
              onClick={handleSubmit(onSubmit)}
            >
              Potwierdź wypłatę
            </Button>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default PayoutSummary;
