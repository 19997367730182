import {
  REGISTER_DEVICE,
  REGISTER_SUCCESS_CLEAR,
  REGISTER_DEVICE_FAILURE,
  REGISTER_DEVICE_REQUEST,
  QUIZ_END,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  GET_ACTIVATION_LIST
} from "../actions/types";

export function activationsReducer(state = {}, action) {
  switch (action.type) {
    case REGISTER_DEVICE:
      return {
        ...state,
        registerInProgress: false,
        payout: action.payload,
        deviceLimit: false,
        fileIdent: ""
      }

    case REGISTER_DEVICE_REQUEST:
      return {
        ...state,
        registerInProgress: true,
      }

    case REGISTER_DEVICE_FAILURE:
      return {
        ...state,
        registerInProgress: false,
      }
      
    case QUIZ_END:
      return {
        ...state,
        quizSuccess: true
      }
    
    case REGISTER_SUCCESS_CLEAR:
      return {
        ...state,
        registerInProgresss: false,
        quizSuccess: false,
        uploadInProgress: false,
        deviceLimit: false
      }

    case UPLOAD_FILE_REQUEST:
      return {
        ...state,
        uploadInProgress: false
      }

    case UPLOAD_FILE_SUCCESS:
      const serial = action.payload.words.find((word)=> word.length > 5)
      return {
        ...state,
        uploadInProgress: true,
        serial: serial,
        fileIdent: action.payload.fileIdent
      }

    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        uploadInProgress: true
      }
    
    case GET_ACTIVATION_LIST:

      const permActivations = [
        { 
          completed: false,
          content: {title: 'Mikołajkowy Bonus'},
          created: {date: '2022-12-05 15:00:00.000000'},
          hero_image: {file:{url:"/img/static05.jpg"}},
          ident: "mikolajki_2022",
          payout: 15,
          type: "perm"
        },
        { 
          completed: false,
          content: {title: 'Black Week'},
          created: {date: '2022-11-24 15:00:00.000000'},
          hero_image: {file:{url:"/img/static04.jpg"}},
          ident: "black_week_2022",
          payout: 10,
          type: "perm"
        },
        { 
          completed: false,
          content: {title: 'Sprzedażowy wyścig'},
          created: {date: '2022-10-10 15:00:00.000000'},
          hero_image: {file:{url:"/img/static03.jpg"}},
          ident: "happy_days_pazdziernik_2022",
          payout: 10,
          type: "perm"
        },
        {
          ident: "uzupelnij_profil",
          type: "perm",
          payout: 5,
          completed: false,
          created: {
            "date": "2022-04-06 13:45:00.000000"
          },
          content: {
            title: "Uzupełnij profil"
          },
          hero_image: {
            file: {
              url: "/img/uzupelnij-profil.jpg"
            }
          }
        }
      ]

      const activations = action.payload.list.slice(0,4);

      return {
        ...state,
        list: [...activations, ...permActivations]
      }

    default:
      return state;
  }
}

export const getPayout = (state) => state.activations.payout;
export const getActivations = (state) => state.activations.list;
export const getRegisterProgress = (state) => state.activations.registerInProgress;
export const getUploadProgress = (state) => state.activations.uploadInProgress;