import React, { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../header/Header";
import Tabs from "../Tabs/Tabs";
import "./PageLayout.scss";
import { useWindowDimensions, useScrollPosition } from "../../hooks";
import { useSelector } from "react-redux";
import { getUI } from "../../store/reducers/ui";
import Onboarding from "../Onboarding/Onboarding";
import Disclaimer from "../Disclaimer/Disclaimer";
import CookiesFooter from "../CookiesFooter/CookiesFooter";

interface Props {
  children: ReactNode;
  withBottom?: boolean;
  clsName?: string;
}

function PageLayout({ children, withBottom, clsName }: Props) {
  const location = useLocation();
  const scrollPosition = useScrollPosition();
  const { width } = useWindowDimensions();
  const changeHeaderToFixed = scrollPosition > 32 && width < 1270;
  const ui = useSelector(getUI);

  const getHeaderClass = (
    withBottom: boolean | undefined,
    changeHeaderToFixed: boolean,
    isRegister: boolean
  ) => {
    let cls = "PageLayout";
    if (withBottom) cls += " with-bottom";
    if (isRegister) cls += " register-page";
    if (changeHeaderToFixed) cls += " scroll";
    if (clsName) cls += ` ${clsName}`;
    //if(!ui.contestInfo) cls+= " with-contest-info"
    return cls;
  };

  const isLoginPage = () => {
    if (
      location.pathname === "/wylogowanie" ||
      location.pathname === "/login" ||
      location.pathname === "/przypomnienie-hasla" ||
      location.pathname === "/rejestracja" ||
      location.pathname === "/rejestracjaglo" ||
      location.pathname === "/nowe-haslo" ||
      location.pathname === "/404"
    ) {
      return true;
    }
    return false;
  };

  const isKentPage =
    location.pathname === "/produkty-bat/kent" ||
    location.pathname === "/produkty-bat/pall-mall"
      ? true
      : false;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div
      className={getHeaderClass(withBottom, changeHeaderToFixed, isLoginPage())}
    >
      {isLoginPage() ? <Header castrated /> : <Header />}
      {children}
      {!isLoginPage() && <Tabs withDisclaimer={isKentPage} />}
      {isKentPage && <Disclaimer />}
      {!isLoginPage() && <Onboarding />}
      {!isLoginPage() && <CookiesFooter />}
    </div>
  );
}

export default PageLayout;
