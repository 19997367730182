import {
  ACCOUNT_BALANCE,
  ACCOUNT_PAYMENT_CREATE,
  ACCOUNT_PAYMENT_REQUEST,
  ACCOUNT_PAYMENT_FAILURE,
  ADD_TOAST,
  REGISTER_SUCCESS_CLEAR,
  ACCOUNT_PAYMENT_HISTORY,
  ACCOUNT_BALANCE_HISTORY,
  ACCOUNT_BALANCE_SUMMARY,
  THEORETICAL_PAYOUT,
  VISIT_STATS,
  ACCOUNT_PAYMENT_OTP_REQUEST,
  ACCOUNT_PAYMENT_OTP_REQUEST_FAILURE,
  ACCOUNT_PAYMENT_OTP_REQUEST_SENDED,
} from "./types";
import { API_URL, appFetch } from "../../helpers/fetch";
import { errors } from "../../helpers/errors";
import { showDrawer } from "./ui";

export function theoreticalPayout() {
  return (dispatch, getState) => {
    const currentState = getState();
    appFetch(
      "POST",
      API_URL,
      "/visit/count",
      false,
      currentState.user?.token?.uuid ? currentState.user?.token?.uuid : false,
      (result) => {
        if (result.status && result.status.success) {
          dispatch({ type: THEORETICAL_PAYOUT, payload: result.data });
        } else {
          //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        }
      },
      (error) => {
        dispatch({
          type: ADD_TOAST,
          payload: { type: "error", message: errors.generic },
        });
      }
    );
  };
}
export function visitStats() {
  return (dispatch, getState) => {
    const currentState = getState();
    appFetch(
      "POST",
      API_URL,
      "/visit/stats",
      false,
      currentState.user?.token?.uuid ? currentState.user?.token?.uuid : false,
      (result) => {
        if (result.status && result.status.success) {
          dispatch({ type: VISIT_STATS, payload: result.data });
        } else {
          //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        }
      },
      (error) => {
        dispatch({
          type: ADD_TOAST,
          payload: { type: "error", message: errors.generic },
        });
      }
    );
  };
}

export function accountBalance(seasonData) {
  return (dispatch, getState) => {
    const currentState = getState();
    dispatch({ type: REGISTER_SUCCESS_CLEAR, payload: {} });
    appFetch(
      "POST",
      API_URL,
      "/user/account/balance",
      false,
      currentState.user?.token?.uuid ? currentState.user.token?.uuid : false,
      (result) => {
        if (result.status && result.status.success) {
          dispatch({
            type: ACCOUNT_BALANCE,
            payload: { balance: result.data.balance, seasons: seasonData },
          });
        } else {
          //ispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        }
      },
      (error) => {
        dispatch({
          type: ADD_TOAST,
          payload: { type: "error", message: errors.generic },
        });
      }
    );
  };
}

export function accountPaymentHistory() {
  return (dispatch, getState) => {
    const currentState = getState();
    dispatch({ type: REGISTER_SUCCESS_CLEAR, payload: {} });
    appFetch(
      "POST",
      API_URL,
      "/user/account/payment-history",
      false,
      currentState.user?.token?.uuid ? currentState.user?.token?.uuid : false,
      (result) => {
        if (result.status && result.status.success) {
          dispatch({
            type: ACCOUNT_PAYMENT_HISTORY,
            payload: { balance: result.data },
          });
        } else {
          //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        }
      },
      (error) => {
        dispatch({
          type: ADD_TOAST,
          payload: { type: "error", message: errors.generic },
        });
      }
    );
  };
}

export function balanceHistory(offset) {
  return (dispatch, getState) => {
    const data = {
      limit: 100,
      offset: offset || 0,
    };
    const currentState = getState();
    dispatch({ type: REGISTER_SUCCESS_CLEAR, payload: {} });
    appFetch(
      "POST",
      API_URL,
      "/user/account/balance-history",
      data,
      currentState.user?.token?.uuid ? currentState.user?.token?.uuid : false,
      (result) => {
        if (result.status && result.status.success) {
          dispatch({
            type: ACCOUNT_BALANCE_HISTORY,
            payload: { balance: result.data.balance },
          });
        } else {
          //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        }
      },
      (error) => {
        dispatch({
          type: ADD_TOAST,
          payload: { type: "error", message: errors.generic },
        });
      }
    );
  };
}

export function balanceSummary() {
  return (dispatch, getState) => {
    const currentState = getState();
    dispatch({ type: REGISTER_SUCCESS_CLEAR, payload: {} });
    appFetch(
      "POST",
      API_URL,
      "/user/account/balance-summary",
      false,
      currentState.user?.token?.uuid ? currentState.user?.token?.uuid : false,
      (result) => {
        if (result.status && result.status.success) {
          // console.log("DATA", result.data.summary);
          dispatch({
            type: ACCOUNT_BALANCE_SUMMARY,
            payload: {
              balance: result.data.summary,
              referral: result.data,
            },
          });
          // dispatch({
          //   type: ACCOUNT_BALANCE_SUMMARY,
          //   payload: { balance: result.data, referral: result.data },
          // });
        } else {
          //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        }
      },
      (error) => {
        dispatch({
          type: ADD_TOAST,
          payload: { type: "error", message: errors.generic },
        });
      }
    );
  };
}

export function payout(data, successCallback) {
  return (dispatch, getState) => {
    const currentState = getState();
    dispatch({ type: ACCOUNT_PAYMENT_REQUEST, payload: {} });
    appFetch(
      "POST",
      API_URL,
      "/user/account/payment-create",
      data,
      currentState.user?.token?.uuid ? currentState.user?.token?.uuid : false,
      (result) => {
        dispatch({
          type: ACCOUNT_PAYMENT_CREATE,
          payload: { paymentStatus: result.data },
        });
        if (result.status && result.status.success) {
          // dispatch(accountBalance());
          dispatch(balanceSummary());
          // dispatch(accountBalance());

          // dispatch(showDrawer("info", { name: "payout" }));
          if (successCallback && typeof successCallback === "function") {
            successCallback();
          }
          //dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Twoja wypłata jest procesowana" } });
        } else {
          dispatch({ type: ACCOUNT_PAYMENT_FAILURE, payload: {} });
          dispatch(
            showDrawer("error", {
              name: result.data.error,
              errorCode: result.meta.ts,
            })
          );
        }
      },
      (error) => {
        dispatch({
          type: ADD_TOAST,
          payload: { type: "error", message: errors.generic },
        });
      }
    );
  };
}

export function sendOtpPayoutRequest(successCallback) {
  const data = {
    otp: {
      purpose: "PAYMENT",
    },
  };

  return (dispatch, getState) => {
    const currentState = getState();
    dispatch({ type: ACCOUNT_PAYMENT_OTP_REQUEST, payload: {} });
    appFetch(
      "POST",
      API_URL,
      "/user/account/payment-otp",
      data,
      currentState.user?.token?.uuid ? currentState.user?.token?.uuid : false,
      (result) => {
        if (result.status && result.status.success) {
          dispatch({ type: ACCOUNT_PAYMENT_OTP_REQUEST_SENDED, payload: {} });
          if (successCallback && typeof successCallback === "function") {
            const twoMinutes = 2 * 60 * 1000;
            const expiredDate = new Date(Date.now() + twoMinutes);
            sessionStorage.setItem("otpDate", expiredDate);

            successCallback();
          }
        } else {
          if (result.data.error === "error_otp_please_wait") {
            successCallback();
          } else {
            dispatch(
              showDrawer("error", {
                name: result.data.error,
                errorCode: result.meta.ts,
              })
            );
            dispatch({
              type: ACCOUNT_PAYMENT_OTP_REQUEST_FAILURE,
              payload: {},
            });
          }
        }
      },
      (error) => {
        dispatch({ type: ACCOUNT_PAYMENT_OTP_REQUEST_FAILURE, payload: {} });
        dispatch(showDrawer("error", { error: "generic" }));
      }
    );
  };
}
