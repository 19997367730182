import {
  GET_RANKING,
  GET_PRIZES,
  GET_STATS_SUCCESS
} from "../actions/types";

export function rankingsReducer(state = {}, action) {
  switch (action.type) {
    case GET_RANKING:
      return {
        ...state,
        list: action.payload.ranking?.ranking,
        user: action.payload.ranking?.user,
        loading:action.payload.loading,
      }
    
    case GET_PRIZES:
      return {
        ...state,
        prize_list: action.payload.ranking?.ranking,
        user: action.payload.ranking?.user,
        loading:action.payload.loading,
      }
    
    case GET_STATS_SUCCESS:
      return {
        ...state,
        list_stats: action.payload?.ranking
      }

    default:
      return state;
  }
}

export const getRanking = (state) => state.rankings.list;
export const getUserRanking = (state) => state.rankings.user;
export const getRankingLoading = (state) => state.rankings.loading;
export const getPrizes = (state) => state.rankings.prize_list;
export const getVisitHistory = (state) => state.rankings.list_stats;