import { 
  GET_ACTIVATION_LIST,
  REGISTER_DEVICE,
  REGISTER_DEVICE_FAILURE,
  REGISTER_DEVICE_REQUEST,
  SUBMIT_QUIZ,
  ADD_TOAST,
  QUIZ_END,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE
} from './types';
import { API_URL, appFetch, fileFetch } from '../../helpers/fetch';
import { errors } from '../../helpers/errors';
import { showDrawer } from './ui';

export function listActivations() {
  return (dispatch, getState) => {
    const currentState = getState();
    const data = {
      withSolutions: false
    }
    appFetch("POST", API_URL, "/activation/list", data, currentState.user.token?.uuid ? currentState.user.token?.uuid : false, (result) => {
        if(result.status && result.status.success) {
            dispatch({ type: GET_ACTIVATION_LIST, payload: { list: result.data.activations } });
        } else {
            dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        }
    }, (error) => {
      dispatch(showDrawer('error', {name: 'generic' }))
    })
  }
}

export function registerVisit(sapid, successCallback, sets) {
  
  return (dispatch, getState) => {
    const currentState = getState();
    const data = {
      "visit": {
        "sapId": sapid,
        "productSet": sets
      }
    }

    dispatch({ type: REGISTER_DEVICE_REQUEST, payload: {} });

    appFetch("POST", API_URL, "/visit/register", data, currentState.user.token?.uuid ? currentState.user.token?.uuid : false, (result) => {
        if(result.status && result.status.success) {
            dispatch({ type: REGISTER_DEVICE, payload: result.data.payout });
            successCallback();
        } else {
          dispatch({ type: REGISTER_DEVICE_FAILURE, payload: {} });
          dispatch(showDrawer('error', {name: result.data.error, errorCode: result.meta.ts }))
        }
    }, (error) => {
      dispatch({ type: REGISTER_DEVICE_FAILURE, payload: {} });
      dispatch(showDrawer('error', {name: 'generic' }))
    })
  }
}

export function uploadFile(file) {

  const fd = new FormData();
  fd.append('files[]', file);
  return (dispatch, getState) => {
    const currentState = getState();

    dispatch({ type: UPLOAD_FILE_REQUEST, payload: {  } });

    fileFetch("POST", API_URL, "/ocr/extract-identify", fd, currentState.user.token?.uuid ? currentState.user.token?.uuid : false, (result) => {
        if(result.status && result.status.success) {
            dispatch({ type: UPLOAD_FILE_SUCCESS, payload: { words: result.data.words, fileIdent: result.data.file.ident } });
        } else {
            dispatch({ type: UPLOAD_FILE_FAILURE, payload: {  } });
            dispatch(showDrawer('error', {name: result.data.error, errorCode: result.meta.ts }))
            //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
            //dispatch({ type: BOTTOM_ERROR, payload: { show: true, message: result.data.error, errorCode: result.meta.ts } });
            //dispatch({ type: SET_STEP, payload: { step: 0 } });
        }
    }, (error) => {
      dispatch({ type: UPLOAD_FILE_FAILURE, payload: {  } });
      dispatch(showDrawer('error', {name: 'generic' }))
    })
  }
}

export function quiz(answers,quizIdent) {
  const data = {
         activation: {
          ident: quizIdent,
          payload: answers,
          debug: true
      }
  }

  return (dispatch, getState) => {
    const currentState = getState();
    appFetch("POST", API_URL, "/activation/solution/create", data, currentState.user.token ? currentState.user.token : false, (result) => {
      if(result.status && result.status.success) {
          if (result.data.payout != 0) {
            dispatch({ type: QUIZ_END, payload: {  } });
            dispatch(showDrawer('congrats', { redirect:'/dashboard' }))
          } else {
            dispatch(showDrawer('error', {name: 'error_activation_waiting_period', errorCode: '', redirect:'/dashboard' }))
          }
        } else { 
          dispatch(showDrawer('error', {name: result.data.error, errorCode: result.meta.ts }))
        }
    }, (error) => {
      dispatch(showDrawer('error', {name: 'generic' }))
    })
  }
}