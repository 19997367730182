import React, { useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./HyperX2Air.scss";
import Title from "../../components/Title/Title";
import mobileHero from "./img/hero.jpg";
import desktopHero from "./img/hero-desktop.jpg";
import Icon01 from "./img/img1.svg";
import Icon02 from "./img/img2.svg";
import Icon03 from "./img/img3.svg";
import Icon04 from "./img/img4.svg";
import airRosey from "./img/airRosey.png";
import airDawn from "./img/airDawn.png";
import airNorthern from "./img/airNorthern.png";
import airOcean from "./img/airOcean.png";
import airVelvet from "./img/airVelvet.png";
import airMoonless from "./img/airMoonless.png";
import airBlack from "./img/airBlack.png";
import gloBg from "./img/glox2.jpg";
import glox2Bg from "./img/airx2.jpg";
import gloBgM from "./img/glox2m.jpg";
import glox2BgM from "./img/airx2m.jpg";
import deviceGLO from "./img/gloDevice.png";
import deviceAIR from "./img/airDevice.png";
import { ReactComponent as IconBack } from "./img/click-icon.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { useWindowDimensions } from "../../hooks";
import { Link } from "react-router-dom";
import { Navigation } from "swiper";
import ArrowButton from "../../components/ArrowButton/ArrowButton";
import Badge from "../../components/Badge/Badge";
function HyperX2Air() {
  const { width } = useWindowDimensions();
  const [cardIndex, setCardIndex] = useState(0);

  const cardsContent = [
    {
      card_l: [
        { title: "Wykończenie:", value: "Soft Touch", break: true },
        { title: "System otwierania:", value: "Obracany", break: true },
        { title: "Kolory:", value: "7", break: false },
      ],
      card_r: [
        { title: "Wykończenie:", value: "Metaliczne", break: true },
        { title: "System otwierania:", value: "Przesuwny", break: true },
        { title: "Kolory:", value: "7", break: false },
      ],
    },
    {
      card_l: [
        { title: "Waga:", value: "102g", break: false },
        { title: "Rozmiar:", value: "77,7 x 43,3 x 21,4 mm", break: true },
        {
          title: "Rozmiar opakowania:",
          value: "109 x 86 x 40 mm",
          break: true,
        },
      ],
      card_r: [
        { title: "Waga:", value: "75g", break: false },
        { title: "Rozmiar:", value: "97,3 x 47,1 x 12,5 mm", break: true },
        {
          title: "Rozmiar opakowania:",
          value: "110 x 64,5 x 43 mm",
          break: true,
        },
      ],
    },
    {
      card_l: [
        { title: "Liczba sesji:", value: "do 22*", break: true },
        {
          title: "Długość sesji/Tryb:",
          value: (
            <>
              4 min (STANDARD) <br />3 min (BOOST)
            </>
          ),
          break: true,
        },
        { title: "Czas ładowania:", value: "do 210 min", break: false },
        { title: "Sesje następujące:", value: "2", break: false },
      ],
      card_r: [
        { title: "Liczba sesji:", value: "do 20*", break: true },
        {
          title: "Długość sesji/Tryb:",
          value: (
            <>
              4 min (STANDARD) <br />3 min (BOOST)
            </>
          ),
          break: true,
        },
        { title: "Czas ładowania:", value: "do 120 min", break: false },
        { title: "Sesje następujące:", value: "3", break: false },
      ],
    },
    {
      card_l: [
        {
          title: "Temperatura podgrzewania Standard:",
          value: "265 ℃",
          break: true,
        },
        {
          title: "Temperatura podgrzewania BOOST:",
          value: "270 ℃",
          break: true,
        },
      ],
      card_r: [
        {
          title: "Temperatura podgrzewania Standard:",
          value: "265 ℃",
          break: true,
        },
        {
          title: "Temperatura podgrzewania BOOST:",
          value: "270 ℃",
          break: true,
        },
      ],
    },
  ];

  const cards = (
    <>
      <div className="card">
        <div
          className="top"
          style={{
            backgroundImage:
              width < 720 ? "url(" + gloBgM + ")" : "url(" + gloBg + ")",
          }}
        >
          <span>hyper X2</span>
          <div className="device">
            <img src={deviceGLO} />
          </div>
        </div>
        <div className="body">
          <p className="desc">
            {cardsContent[cardIndex].card_l.map((item, key) => {
              return (
                <span>
                  <strong>{item.title}</strong>
                  {item.break && <br />} {item.value}
                </span>
              );
            })}
          </p>
        </div>
      </div>
      <div className="card">
        <div
          className="top"
          style={{
            backgroundImage:
              width < 720 ? "url(" + glox2BgM + ")" : "url(" + glox2Bg + ")",
          }}
        >
          <span>
            hyper X2 Air <br />
          </span>
          <div className="device">
            <img src={deviceAIR} />
          </div>
        </div>
        <div className="body">
          <p className="desc">
            {cardsContent[cardIndex].card_r.map((item, key) => {
              return (
                <span>
                  <strong>{item.title}</strong>
                  {item.break && <br />} {item.value}
                </span>
              );
            })}
          </p>
        </div>
      </div>
    </>
  );

  return (
    <PageLayout>
      <section className="HyperX2Air">
        <div className="hero-section">
          <Badge category="Podgrzewacz tytoniu" />
          <div className="hero-image">
            <picture>
              <source media="(max-width: 1019px)" srcSet={mobileHero} />
              <source media="(min-width: 1020px)" srcSet={desktopHero} />
              <img className="bg" src={desktopHero} alt="A wide image" />
            </picture>
          </div>
          <div className="wrapper">
            <div className="hero-content">
              <Title></Title>
              <h1 className="page-title">
                Nowe glo™ <br />
                <span>Hyper&nbsp;X2&nbsp;Air</span>
              </h1>
              <p className="subtext">
                glo™ hyper X2 Air to pierwsze tak smukłe i lekkie urządzenie w
                ofercie glo™. Niebanalna kolorystyka oraz poręczna forma
                sprawiają, że glo™ hyper X2 Air to najlepsza propozycja dla
                konsumentów szukających stylowych rozwiązań
                w&nbsp;kompaktowej&nbsp;formie.
              </p>
            </div>
          </div>
        </div>

        <div className="air-cards">
          <h2 className="color-white">
            Co wyróżnia glo™ hyper&nbsp;X2&nbsp;Air?
          </h2>
          <Swiper
            slidesPerView={1.2}
            spaceBetween={16}
            centeredSlides={true}
            className="recommend-swiper"
            breakpoints={{
              1020: {
                slidesPerView: 4,
                spaceBetween: 10,
                centeredSlides: false,
              },
              1270: {
                slidesPerView: 4,
                spaceBetween: 20,
                centeredSlides: false,
              },
            }}
          >
            <SwiperSlide>
              <div className="recommend-swiper-slide">
                <div className="top">
                  <div className="number">1</div>
                </div>
                <div className="body">
                  <h3 className="title">Najlżejsze glo™</h3>
                  <p className="desc">25% lżejsze niż glo™ hyper X2</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="recommend-swiper-slide">
                <div className="top">
                  <div className="number">2</div>
                </div>
                <div className="body">
                  <h3 className="title">Opływowa linia</h3>
                  <p className="desc">
                    Najsmuklejsze urządzenie tylko 12.5 mm grubości
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="recommend-swiper-slide">
                <div className="top">
                  <div className="number">3</div>
                </div>
                <div className="body">
                  <h3 className="title">Precyzja wykonania</h3>
                  <p className="desc">
                    Obudowa ze stopu aluminium oraz żłobione, zasuwane
                    zamknięcie
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="recommend-swiper-slide">
                <div className="top">
                  <div className="number">4</div>
                </div>
                <div className="body">
                  <h3 className="title">Wskaźnik LED</h3>
                  <p className="desc">4-stopniowa kolorowa sygnalizacja</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        <section className="device-section">
          <div className="card devices-card">
            <div className="top">
              <h2>
                glo™ hyper X2 Air dostępny
                jest&nbsp;w&nbsp;7&nbsp;stylowych&nbsp;wariantach
                kolorystycznych:
              </h2>
            </div>
            <div className="body">
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                centeredSlides={true}
                className="devices"
                breakpoints={{
                  1020: {
                    slidesPerView: 7,
                    spaceBetween: 20,
                    centeredSlides: false,
                  },
                  1270: {
                    slidesPerView: 7,
                    spaceBetween: 40,
                    centeredSlides: false,
                  },
                }}
                navigation={{
                  nextEl: ".devices-btn-next",
                  prevEl: ".devices-btn-prev",
                }}
                modules={[Navigation]}
              >
                <SwiperSlide>
                  <div className="device">
                    <img src={airRosey} />
                    <p>rosey gold</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="device">
                    <img src={airDawn} />
                    <p>crisp purple</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="device">
                    <img src={airNorthern} />
                    <p>light teal</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="device">
                    <img src={airOcean} />
                    <p>ocean blue</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="device">
                    <img src={airVelvet} />
                    <p>velvet pink</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="device">
                    <img src={airMoonless} />
                    <p>space navy</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="device">
                    <img src={airBlack} />
                    <p>Black</p>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className="devices-btn devices-btn-next">
                <ArrowButton color="white" />
              </div>
              <div className="devices-btn devices-btn-prev">
                <ArrowButton color="white" />
              </div>
            </div>
          </div>
        </section>

        <div className="qr">
          <div className="wrapper">
            <header className="header">
              <h2>Czym różni się glo™ hyper X2 od glo™ hyper X2 Air?</h2>
            </header>
            <div className="card-control">
              <div
                className={
                  cardIndex === 0 ? "control-btn active" : "control-btn"
                }
                onClick={() => setCardIndex(0)}
              >
                Design
              </div>
              <div
                className={
                  cardIndex === 1 ? "control-btn active" : "control-btn"
                }
                onClick={() => setCardIndex(1)}
              >
                Rozmiar
              </div>
              <div
                className={
                  cardIndex === 2 ? "control-btn active" : "control-btn"
                }
                onClick={() => setCardIndex(2)}
              >
                Sesje
              </div>
              <div
                className={
                  cardIndex === 3 ? "control-btn active" : "control-btn"
                }
                onClick={() => setCardIndex(3)}
              >
                Temperatura
              </div>
            </div>
            <div className="cards">{cards}</div>
            {cardIndex === 1 && (
              <p className="text-center">
                *Po pełnym naładowaniu baterii, uzależnione od sposobu
                użytkowania.
              </p>
            )}
          </div>
        </div>

        <div className="footer">
          <h2>Co zawiera w sobie nowy zestaw startowy glo™ hyper X2 Air?</h2>
          <div className="cards">
            <div className="card">
              <img src={Icon01} alt="az-icon" />
              <p className="desc">Urządzenie glo™ hyper X2 Air </p>
            </div>
            <div className="card">
              <img src={Icon02} alt="az-icon" />
              <p className="desc">Szczoteczka do&nbsp;czyszczenia</p>
            </div>
            <div className="card">
              <img src={Icon03} alt="az-icon" />
              <p className="desc">Kabel USB-C</p>
            </div>
            <div className="card">
              <img src={Icon04} alt="az-icon" />
              <p className="desc">Instrukcja obsługi</p>
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  );
}

export default HyperX2Air;
