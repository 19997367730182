import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Remind from "./pages/Remind/Remind";
import NewPass from "./pages/NewPass/NewPass";
import Dashboard from "./pages/Dashboard/Dashboard";
import Stats from "./pages/Stats/Stats";
import Page404 from "./pages/404/Page404";
import News from "./pages/News/News";
import NewsDetails from "./components/NewsDetails/NewsDetails";
import Payout from "./pages/Payout/Payout";
import Profile from "./pages/Profile/Profile";
import Contact from "./pages/Contact/Contact";
import About from "./pages/About/About";
import ShortcutPage from "./pages/ShortcutPage/ShortcutPage";
import Nav from "./components/Nav/Nav";
import TokenRefresh from "./components/TokenRefresh/TokenRefresh";
import GloUniq from "./pages/GloUniq/GloUniq";
import GloHyper from "./pages/GloHyper/GloHyper";
import HyperX2 from "./pages/HyperX2/HyperX2";
import HyperX2Air from "./pages/HyperX2Air/HyperX2Air";
import Velo from "./pages/Velo/Velo";
import Faq from "./pages/Faq/Faq";
import Regulations from "./pages/Regulations/Regulations";
import NewSaleDetails from "./pages/NewSale/NewSaleDetails/NewSaleDetails";
import NewSaleSerialNumber from "./pages/NewSale/NewSaleSerialNumber/NewSaleSerialNumber";
import Notifications from "./pages/Notifications/Notifications";
import Drawer from "./components/Drawer/Drawer";
import NotificationToasts from "./components/NotificationToasts/NotificationToasts";
import SaleRules from "./pages/SaleRules/SaleRules";
import Logout from "./pages/Logout/Logout";
import Kent from "./pages/Kent/Kent";
import VuseGo from "./pages/VuseGo/VuseGo";
import PallMall from "./pages/PallMall/PallMall";
import Products from "./pages/Products/Products";
import Complaint from "./pages/Complaint/Complaint";
import Consent from "./pages/Consent/Consent";
import PayoutSummary from "./pages/PayoutSummary/PayoutSummary";
import PayoutSucess from "./pages/PayoutSucess/PayoutSuccess";

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/wylogowanie" element={<Logout />} />
        <Route path="/rejestracja" element={<Register />} />
        <Route path="/przypomnienie-hasla" element={<Remind />} />
        <Route path="/nowe-haslo" element={<NewPass />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/wyplata"
          element={
            <ProtectedRoute>
              <Payout />
            </ProtectedRoute>
          }
        />
        <Route
          path="/wyplata/podsumowanie"
          element={
            <ProtectedRoute>
              <PayoutSummary />
            </ProtectedRoute>
          }
        />
         <Route
          path="/wyplata/zakonczona"
          element={
            <ProtectedRoute>
              <PayoutSucess />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profil"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/statystyki"
          element={
            <ProtectedRoute>
              <Stats />
            </ProtectedRoute>
          }
        />
        <Route
          path="/news"
          element={
            <ProtectedRoute>
              <News />
            </ProtectedRoute>
          }
        />
        <Route
          path="/news/:slug"
          element={
            <ProtectedRoute>
              <NewsDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/kontakt"
          element={
            <ProtectedRoute>
              <Contact />
            </ProtectedRoute>
          }
        />
        <Route
          path="/utworz-skrot"
          element={
            <ProtectedRoute>
              <ShortcutPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/produkty-bat/velo"
          element={
            <ProtectedRoute>
              <Velo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/produkty-bat/glo-hyper-uniq"
          element={
            <ProtectedRoute>
              <GloUniq />
            </ProtectedRoute>
          }
        />
        <Route
          path="/produkty-bat/glo-hyper"
          element={
            <ProtectedRoute>
              <GloHyper />
            </ProtectedRoute>
          }
        />
        <Route
          path="/produkty-bat/glo-hyper-x2"
          element={
            <ProtectedRoute>
              <HyperX2 />
            </ProtectedRoute>
          }
        />
        <Route
          path="/produkty-bat/glo-hyper-x2-air"
          element={
            <ProtectedRoute>
              <HyperX2Air />
            </ProtectedRoute>
          }
        />
        <Route
          path="/faq"
          element={
            <ProtectedRoute>
              <Faq />
            </ProtectedRoute>
          }
        />
        <Route
          path="/poznaj-zasady-sprzedazy"
          element={
            <ProtectedRoute>
              <SaleRules />
            </ProtectedRoute>
          }
        />
        <Route
          path="/regulaminy"
          element={
            <ProtectedRoute>
              <Regulations />
            </ProtectedRoute>
          }
        />
        <Route
          path="/nowa-sprzedaz"
          element={<Navigate to="/rejestracja-wizyty" />}
        />
        <Route
          path="/rejestracja-wizyty"
          element={
            <ProtectedRoute>
              <NewSaleSerialNumber />
            </ProtectedRoute>
          }
        />
        <Route
          path="/rejestracja-wizyty/success"
          element={
            <ProtectedRoute>
              <NewSaleDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/notyfikacje"
          element={
            <ProtectedRoute>
              <Notifications />
            </ProtectedRoute>
          }
        />

        <Route
          path="/produkty-bat/kent"
          element={
            <ProtectedRoute>
              <Kent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/produkty-bat/vusego"
          element={
            <ProtectedRoute>
              <VuseGo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/produkty-bat/pall-mall"
          element={
            <ProtectedRoute>
              <PallMall />
            </ProtectedRoute>
          }
        />
        <Route
          path="/produkty-bat"
          element={
            <ProtectedRoute>
              <Products />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reklamacje"
          element={
            <ProtectedRoute>
              <Complaint />
            </ProtectedRoute>
          }
        />
        <Route
          path="/zgoda/:ident"
          element={
            <ProtectedRoute>
              <Consent />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/kent" element={<Navigate to="/produkty-bat/kent" />} />
        <Route path="/404" element={<Page404 />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      <Nav />
      <Drawer />
      <NotificationToasts />
      <TokenRefresh />
    </Router>
  );
}

export default AppRoutes;
