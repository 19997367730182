import Button from "../../../components/Button/Button";
import Loading from "../../../components/Loading/LoadingScreen/Loading";
import { useForm } from "react-hook-form";
import { getSubmitState } from "../../../store/reducers/user";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../hooks";
import { sendRegisterOtp } from "../../../store/actions/user";
import "./ConsentsStep.scss";
import { useState } from "react";

const ConsentsStep = ({
  handlePrevStep,
  setFormValues,
  handleNextStep,
  formValues,
}: any) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,

    formState: { errors },
  } = useForm({
    defaultValues: {
      ageVerified: formValues?.ageVerified ?? false,
      consentAgreement: formValues?.consentAgreement ?? false,
      phoneContact: formValues?.phoneContact ?? false,
      emailContact: formValues?.emailContact ?? false,
    },
  });
  const dispatch = useAppDispatch();
  const [submittingOtp,setSubmittingOtp] = useState(false);


  const ageVerifiedValue = watch("ageVerified");
  const consentAgreementValue = watch("consentAgreement");
  const emailContactValue = watch("emailContact");
  const phoneContactValue = watch("phoneContact");

  const valuesToSave = {
    ageVerified: ageVerifiedValue,
    consentAgreement: consentAgreementValue,
    phoneContact: phoneContactValue,
    emailContact: emailContactValue,
  };

  const onSubmit = () => {
    setSubmittingOtp(true)
    setFormValues((prev: any) => ({
      ...prev,
      ...valuesToSave,
      marketingConsent: phoneContactValue || emailContactValue ? true : false,
    }));
    let phone = formValues.mobilePhone;
    if (phone.indexOf("+48") === -1) {
      phone = "+48" + phone;
    }
    dispatch(
      sendRegisterOtp(
        {
          user: {
            mobilePhone: phone,
          },
        },
        () => {
          handleNextStep();
          setSubmittingOtp(false);
        },
        () => {
          setSubmittingOtp(false);
        }
      )
    );
  };
  const onError = (errros: any) => {
    console.log(errros, "err");
  };
  return (
    <div className="ConsentsStep">
      <div className="form-step">
        <div className="checkbox-item">
          <div
            onClick={() => setValue("ageVerified", !ageVerifiedValue)}
            className="label-text"
          >
            Potwierdzam, że jestem osobą pełnoletnią i&nbsp;jestem uprawniony/a
            do wzięcia udziału w Programie zgodnie z postanowieniami Regulaminu*
          </div>
          <input
            type="checkbox"
            id="ageVerified"
            {...register("ageVerified", { required: true })}
          />

          <label
            htmlFor={"ageVerified"}
            className={ageVerifiedValue ? "check active" : "check"}
          >
            {ageVerifiedValue ? "Tak" : "Nie"}
          </label>
        </div>

        <div className="checkbox-item">
          <div
            onClick={() => setValue("consentAgreement", !consentAgreementValue)}
            className="label-text"
          >
            Akceptuję warunki Programu określone w{" "}
            <a
              onClick={(e) => e.stopPropagation()}
              href="/pdf/regulamin_bat_partners_hurt.pdf"
              target="_blank"
            >
              Regulaminie Programu*
            </a>
          </div>
          <input
            type="checkbox"
            id="consentAgreement"
            {...register("consentAgreement", { required: true })}
          />

          <label
            htmlFor={"consentAgreement"}
            className={consentAgreementValue ? "check active" : "check"}
          >
            {consentAgreementValue ? "Tak" : "Nie"}
          </label>
        </div>
        {(errors.consentAgreement || errors.ageVerified) && (
          <div className="error-box">Zaznacz wymagane zgody</div>
        )}
        <p className="text-11" style={{ margin: ".5em 0 1em" }}>
          Wyrażam zgodę na otrzymywanie od British American Tobacco Polska
          Trading sp. z o.o. z siedzibą w Warszawie (BAT) informacji
          marketingowych drogą elektroniczną lub telefoniczną, w tym informacji
          na temat produktów i usług oferowanych przez BAT oraz informacji
          marketingowych dotyczących programów wsparcia sprzedaży BAT.
        </p>
        <p className="text-11" style={{ margin: ".5em 0 1em" }}>
          Jako formę kontaktu na potrzeby otrzymywania informacji marketingowych
          wybieram:
        </p>

        <div className="checkbox-item">
          <div
            onClick={() => setValue("emailContact", !emailContactValue)}
            className="label-text"
          >
            wiadomości przesyłane drogą elektroniczną (e-mail, SMS, serwisy
            internetowe, okienka pop up)
          </div>
          <input
            type="checkbox"
            id="emailContact"
            {...register("emailContact")}
          />

          <label
            htmlFor={"emailContact"}
            className={emailContactValue ? "check active" : "check"}
          >
            {emailContactValue ? "Tak" : "Nie"}
          </label>
        </div>
        <div className="checkbox-item">
          <div
            onClick={() => setValue("phoneContact", !phoneContactValue)}
            className="label-text"
          >
            połączenia głosowe (rozmowy telefoniczne, komunikaty IVR)
          </div>
          <input
            type="checkbox"
            id="phoneContact"
            {...register("phoneContact")}
          />

          <label
            htmlFor={"phoneContact"}
            className={phoneContactValue ? "check active" : "check"}
          >
            {phoneContactValue ? "Tak" : "Nie"}
          </label>
        </div>

        <div className="disclaimer">
          W każdej chwili możesz wycofać zgodę na otrzymywanie informacji
          marketingowych ze skutkiem na przyszłość, wysyłając oświadczenie
          o wycofaniu zgody na adres e-mail kontakt@batpartners.pl,
          telefonicznie pod numerem: 800 610 610 lub w panelu ustawień
          użytkownika serwisu www.batpartners.pl. Wycofanie zgody nie wpływa na
          zgodność z prawem wysyłania informacji marketingowych, którego
          dokonano na podstawie zgody przed jej wycofaniem. Więcej informacji o
          tym, jakie dane zbieramy, w jakim celu oraz do czego je wykorzystujemy
          znajdziesz w{" "}
          <a href="/pdf/klauzula_informacy_na_bp_hurt.pdf" target="_blank">
            Klauzuli Informacyjnej
          </a>
          .
        </div>
        <div className="disclaimer disclaimer-bold margin-20">
          * Zgody konieczne do wzięcia udziału w Programie.
        </div>
        <div className="form-nav-buttons margin-20">
          {submittingOtp ? (
            <Button>
              <Loading />
            </Button>
          ) : (
            <Button onClick={handleSubmit(onSubmit, onError)}>Dalej</Button>
          )}
          <Button
            onClick={() => {
              setFormValues((prev: any) => ({
                ...prev,
                ...valuesToSave,
              }));
              handlePrevStep();
            }}
            type="button"
            color="white"
          >
            Cofnij
          </Button>
        </div>

        {/* <Link className="link" to="/">Powrót do logowania</Link> */}
      </div>
    </div>
  );
};
export default ConsentsStep;
