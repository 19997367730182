import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as IconHome } from "./img/icon-home.svg";
import { ReactComponent as IconNews } from "./img/icon-document.svg";
import { ReactComponent as IconActivities } from "./img/icon-activities.svg";
import { ReactComponent as IconWallet } from "./img/icon-wallet.svg";
import { ReactComponent as IconAdd } from "./img/icon-add.svg";
import { ReactComponent as Star } from "./img/icon-star.svg";
import { ReactComponent as StarLine } from "./img/icon-star-line.svg";
import "./Tabs.scss";
import { useAnalyticsEventTracker } from "../../hooks";

interface Props {
  withDisclaimer?: boolean;
}

function Tabs({ withDisclaimer }: Props) {
  const gaEventTracker = useAnalyticsEventTracker("Nav Tabs Event");
  const december = new Date() > new Date("2023-12-01T23:59:00.000");

  return (
    <div className={withDisclaimer ? "Tabs disclaimer" : "Tabs"}>
      <div className="container">
        <div className="inner">
          <div className="tab-nav">
            <NavLink
              to="/dashboard"
              onClick={() => {
                gaEventTracker("click", "Dashboard");
              }}
            >
              <div className="nav-icon to-home">
                <IconHome />
              </div>
              <span>Dashboard</span>
            </NavLink>
          </div>
          <div className="tab-nav">
            <NavLink
              to="/news"
              onClick={() => {
                gaEventTracker("click", "News");
              }}
            >
              <div className="nav-icon to-news">
                <IconNews />
              </div>
              <span>News</span>
            </NavLink>
          </div>
          {/* <div className="tab-nav">
            <NavLink
              to="/rejestracja-wizyty"
              onClick={() => {
                gaEventTracker("click", "Zadanie");
              }}
            >
              <div className="nav-icon to-register">
                <IconActivities />
              </div>
              <span>Zadanie</span>
            </NavLink>
          </div> */}
          <div className="tab-nav">
            <NavLink
              to="/wyplata"
              onClick={() => {
                gaEventTracker("click", "Wyplata");
              }}
            >
              <div className="nav-icon to-payout">
                <IconWallet />
              </div>
              <span>Wypłać</span>
            </NavLink>
          </div>

          <div className="tab-btn-sale">
            <NavLink
              to={"/rejestracja-wizyty"}
              onClick={() => {
                gaEventTracker("click", "Nowa sprzedaż");
              }}
            >
              <div className={"nav-icon"}>
                <Star />
                <div className="line">
                  <StarLine/>
                </div>
              </div>
              <span>
                Zrealizuj <br />
                zadanie
              </span>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tabs;
