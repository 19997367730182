import { 
  GET_RANKING,
  GET_PRIZES,
  ADD_TOAST,
  GET_STATS,
  GET_STATS_SUCCESS,
  GET_STATS_ERROR
} from './types';
import { API_URL, appFetch, fileFetch } from '../../helpers/fetch';
import { errors } from '../../helpers/errors';
import { showDrawer } from './ui';

export function listRanking(quarter, type) {
  return (dispatch, getState) => {
    dispatch({ type: GET_RANKING, payload: { ranking: [],loading:true } });
      let rankingType = type || 'ranking';
      let data = quarter ? {quarter: quarter} : null;
      if (type == "ranking-halloween") {
        data = {edition: quarter}
      }
      if (type == "ranking-podroz") {
        data = {edition: quarter}
      }
      const currentState = getState();
      appFetch("POST", API_URL, "/activation/"+rankingType, data, currentState.user?.token?.uuid ? currentState.user?.token?.uuid : false, (result) => {
          if(result.status && result.status.success) {
              dispatch({ type: GET_RANKING, payload: { ranking: result.data, loading: false } });
          } else {
            dispatch({ type: GET_RANKING, payload: { ranking: [],loading:false } });
              //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
          }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function listStats() {
  return (dispatch, getState) => {
    dispatch({ type: GET_STATS, payload: { ranking: [], loading:true } });
    const data = {
      "limit": 100,
      "offset": 0,
      "verified": null,
      "pending": null
    }
      const currentState = getState();
      appFetch("POST", API_URL, "/visit/list", data, currentState.user?.token?.uuid ? currentState.user?.token?.uuid : false, (result) => {
          if(result.status && result.status.success) {
              dispatch({ type: GET_STATS_SUCCESS, payload: { ranking: result.data, loading: false } });
          } else {
            dispatch({ type: GET_STATS_ERROR, payload: { ranking: [],loading:false } });
              //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
          }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function listPrizes() {
  return (dispatch, getState) => {
    //dispatch({ type: GET_RANKING, payload: { ranking: [],loading:true } });
      const currentState = getState();
      appFetch("POST", API_URL, "/prize/list", null, currentState.user?.token?.uuid ? currentState.user?.token?.uuid : false, (result) => {
          if(result.status && result.status.success) {
              dispatch({ type: GET_PRIZES, payload: result.data });
          } else {
            //dispatch({ type: GET_RANKING, payload: { ranking: [],loading:false } });
              //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
          }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}