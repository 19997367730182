import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageLayout from "../../components/PageLayout/PageLayout";
import { getRegisterState } from "../../store/reducers/user";
import "../../theme/forms.scss";
import RegisterSuccessful from "./RegisterSuccessful/RegisterSuccessful";
import "./Register.scss";
import { useAppDispatch } from "../../hooks";
import Loading from "../../components/Loading/LoadingScreen/Loading";
import loginImgDesktop from "./img/desktop-img.jpg";
import loginImgMobile from "./img/mobile-img.jpg";
import DetailsStep from "./DetailsStep/DetailsStep";
import OtpStep from "./OtpStep/OtpStep";
import ConsentsStep from "./ConsentsStep/ConsentsStep";

interface registerProps {
  firstName: string;
  lastName: string;
  salesPoint: string;
}

function RegisterPage() {
  const registerSuccess = useSelector(getRegisterState);
  const [formStep, setFormStep] = useState(0);
  const [formValues, setFormValues] = useState<any>();

  const handleNextStep = () => {
    window.scrollTo(0, 0);
    setFormStep((prev) => prev + 1);
  };
  const handlePrevStep = () => {
    window.scrollTo(0, 0);
    setFormStep((prev) => prev - 1);
  };

  return (
    <PageLayout>
      <div className="page Register">
        <div className="register-image">
          <picture>
            <source media="(min-width: 1020px)" srcSet={loginImgDesktop} />
            <img
              src={loginImgMobile}
              alt={"Wejdź do programu Bat"}
              loading="lazy"
            />
          </picture>
        </div>

        {!registerSuccess && (
          <div className="body-form">
            <h1 className="title">Dołącz do BAT&nbsp;Partners&nbsp;HURT</h1>
            <FormNavigation formStep={formStep} />
            <div className="inputs-container">
              {formStep === 0 && (
                <DetailsStep
                  formValues={formValues}
                  setFormValues={setFormValues}
                  handleNextStep={handleNextStep}
                />
              )}
              {formStep === 1 && (
                <ConsentsStep
                  formValues={formValues}
                  setFormValues={setFormValues}
                  handlePrevStep={handlePrevStep}
                  handleNextStep={handleNextStep}
                />
              )}
              {formStep === 2 && (
                <OtpStep
                  phoneNumber={formValues?.mobilePhone}
                  handlePrevStep={handlePrevStep}
                  handleNextStep={handleNextStep}
                  setFormValues={setFormValues}
                  formValues={formValues}
                />
              )}
            </div>
          </div>
        )}
        {registerSuccess && <RegisterSuccessful />}
      </div>
      {!registerSuccess && <InfoBottom />}
    </PageLayout>
  );
}

export default RegisterPage;

function InfoBottom() {
  return (
    <p className="info-bottom">
      Administratorem danych wskazanych w trakcie rejestracji jest British
      American Tobacco Polska Trading sp. z o.o. z siedzibą w Warszawie przy ul.
      Krakowiaków 48. Skontaktuj się z nami telefonicznie pod numerem 800 610
      610 lub mailowo na adres{" "}
      <a href="mailto:daneosobowe@bat.com.pl">daneosobowe@bat.com.pl</a> Dane
      będą przez nas wykorzystywane dla celów związanych z Twoim uczestnictwem w
      programie BAT Partners, w tym utworzenia Twojego konta uczestnika. W
      związku z podaniem danych osobowych przysługuje Ci prawo dostępu do treści
      Twoich danych, prawo ich poprawiania, usunięcia lub przenoszenia, a także
      prawo do żądania ograniczenia ich przetwarzania. Przysługuje Ci także
      prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych. Więcej
      informacji znajdziesz w informacji o przetwarzaniu danych na końcu{" "}
      <a href="/pdf/klauzula_informacy_na_bp_hurt.pdf" target="_blank">
        Klauzuli Informacyjnej
      </a>
      .<br />
      <br />W razie pytań lub problemów technicznych napisz do nas na adres{" "}
      <a href="mailto:kontakt@batpartners.pl">kontakt@batpartners.pl</a>
    </p>
  );
}
const FormNavigation = ({ formStep }: { formStep: number }) => {
  return (
    <div className="register-page-nav">
      <p className="desc">
        {formStep === 0 && "Dane konta"}
        {formStep === 1 && "Zgody"}
        {formStep === 2 && "Weryfikacja numeru"}
      </p>
      <ul className="list">
        <li className={formStep >= 0 ? "active" : ""}>1</li>
        <li className={formStep >= 1 ? "active" : ""}>2</li>
        <li className={formStep >= 2 ? "active" : ""}>3</li>
      </ul>
    </div>
  );
};
