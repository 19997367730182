import "./RegisterSuccessful.scss";
import Button from "../../../components/Button/Button";

const RegisterSuccessful = () => {
  return (
    <div className="RegisterSuccessful">
      <div className="body">
        <h1 className="title">Sprawdź maila!</h1>
        <p className="sub">Pomyślnie utworzyliśmy Twoje konto.</p>
        <div className="content">
          <p>
            Ale zanim zaczniesz z niego korzystać {" "}
            <strong>musisz je aktywować.</strong>{" "}
          </p>
          <p>
            <strong>
              Wysłaliśmy Ci wiadomość aktywacyjną na adres e-mail podany podczas
              rejestracji. Powinien dotrzeć za kilka minut.
            </strong>
          </p>
          <p>
            Jeśli wiadomość nie dotrze <strong>w ciągu 5 minut </strong>
            sprawdź&nbsp;skrzynkę SPAM.{" "}
          </p>
        </div>
        <Button path="/">Dalej</Button>
      </div>
    </div>
  );
};
export default RegisterSuccessful;
