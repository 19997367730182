import { 
  LOGIN,
  LOGOUT,
  USER_DETAILS,
  REQUEST_RESET_PASS,
  RESET_PASS,
  REMIND,
  ADD_TOAST,
  SET_TOKEN,
  SET_OTP_TOKEN,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS_CLEAR,
  WELCOME_SCREEN_SHOWN,
  GET_MESSAGES,
  GET_MESSAGES_SUCCESS,
  ON_LOGIN_POPUP_OPEN,
  ON_LOGIN_POPUP_CLOSE,
  REGISTER_GLO_SUCCESS,
  SET_OTP_GLO_TOKEN,
  COUPON_REGISTER_SUCCESS,
  COUPON_LIST,
  DASH_TIMER
} from './types';
import { API_URL, API_WEBFORM_URL, appFetch } from '../../helpers/fetch';
import { errors, errorHandler } from '../../helpers/errors';
import { showDrawer } from './ui';
import { fetchNotifications } from './notifications';

const URL = window.location.hostname;

export function loginUser(data, navigate) {
  return (dispatch, getState) => { 
    
    appFetch("POST", API_URL, "/user/login", {user: data}, false, (result) => {
        if(result.status && result.status.success) {
            localStorage.setItem("token", JSON.stringify(result.data.token));
            dispatch({ type: LOGIN, payload: { token: result.data.token } });
            dispatch(getUserDetails(navigate, true, result.data.token.uuid))
            // navigate("/dashboard", {replace: true})
            dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Zalogowano" } });
            //dispatch({ type: ON_LOGIN_POPUP_OPEN, payload: { } });
            // dispatch(showDrawer('login-info'))
        } else {
          dispatch(showDrawer('error', {name: result.data.error, errorCode: result.meta.ts }))
        }
    }, (error) => {
      dispatch(showDrawer('error', { error: 'generic' }))
    })
      
  }
}

export function getUserDetails(navigate, redirect, token) {
  return (dispatch, getState) => { 
    const currentState = getState()
      appFetch("POST", API_URL, "/user/details", false, token ? token : currentState.user.token.uuid, (result) => {
          if(result.status && result.status.success) {
              dispatch({ type: USER_DETAILS, payload: { user: result.data.user } });
              if(!result.data.user?.consent022024){
                navigate && navigate("/zgoda/consent022024", {replace: true})
              } else if(redirect) {
                /*if(history && history.location.pathname === '/') {*/
                  navigate && navigate("/dashboard", {replace: true})
                /*}*/
              }
              dispatch(fetchNotifications())
          } else {
              dispatch({ type: USER_DETAILS, payload: { token: null, user: null } });
              //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
              localStorage.removeItem("token");
              if (navigate) { 
                navigate("/", {replace: true});
              } else {
                window.location.replace('/')
              }
          }
      }, (error) => {
        dispatch(showDrawer('error', { error: 'generic' }))
      })
  }
}

export function logout(navigate,url) {
  return (dispatch, getState) => { 
    const currentState = getState()
      appFetch("POST", API_URL, "/user/logout", false, currentState.user?.token?.uuid , (result) => {
          if(result.status && result.status.success) {
            navigate(url ? url : "/wylogowanie", {replace: true})
            localStorage.removeItem("token");
              dispatch({ type: LOGOUT, payload: null });
          } else {
            navigate(url ? url : "/wylogowanie", {replace: true})
            localStorage.removeItem("token");
            dispatch({ type: LOGOUT, payload: null });
          }
      }, (error) => {
        dispatch(showDrawer('error', { error: 'generic' }))
      })
  }
}

export function refreshToken() {
  localStorage.removeItem("token");
  return (dispatch, getState) => { 
    const currentState = getState()
      appFetch("POST", API_URL, "/user/refresh", false, currentState.user?.token?.uuid , (result) => {
          if(result.status && result.status.success) {
             localStorage.setItem('token', JSON.stringify(result.data.token));
          } else {
              localStorage.removeItem("token");
          }
      }, (error) => {
        dispatch(showDrawer('error', { error: 'generic' }))
      })
  }
}

export function registerUser(data) {
  return (dispatch, getState) => { 
      const rdata = {
        user: data,
        reset: {
          return: {
            url: 'https://'+URL+"/nowe-haslo?reset="
          }
        }
      }
      dispatch({ type: REGISTER_REQUEST, payload: { } });
      appFetch("POST", API_URL, "/user/register", rdata, false, (result) => {
          if(result.status && result.status.success) {
            //dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Rejestracja udana" } });
            dispatch({ type: REGISTER_SUCCESS, payload: { } });
          } else {
            dispatch(showDrawer('error', {name: result.data.error, errorCode: result.meta.ts }))
            dispatch({ type: REGISTER_FAILURE, payload: { } });
          }
      }, (error) => {
          dispatch(showDrawer('error', { error: 'generic' }))
          dispatch({ type: REGISTER_FAILURE, payload: { } });
      })
      
  }
}


export function remind(data, navigate) {
  return (dispatch, getState) => { 
    const currentState = getState()
    appFetch("POST", API_URL, "/user/reset-token", data, currentState.user?.token?.uuid ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
        dispatch({ type: REMIND, payload: data.username });
        navigate("/remind/code", {replace: true})
      } else {
        dispatch(showDrawer('error', {name: result.data.error, errorCode: result.meta.ts }))
        navigate("/remind", {replace: true})
      }
    }, (error) => {
      dispatch(showDrawer('error', { error: 'generic' }))
    })
  }
}

export function requestResetPass(email) {
  return (dispatch, getState) => {
    const currentState = getState()
    const data = {
        "user": {
            "emailAddress": email
        },
        "reset": {
            "return": {
                "url": 'https://'+URL+"/nowe-haslo?reset="
            }
        }
    }

    appFetch("POST", API_URL, "/user/request-reset-password", data, false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: REQUEST_RESET_PASS, payload: null });
          //dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Zmieniono hasło." } });
      } else {
        dispatch(showDrawer('error', {name: result.data.error, errorCode: result.meta.ts }))
      }
    }, (error) => {
      dispatch(showDrawer('error', { error: 'generic' }))
    })
  }
}

export function resetPass(data, token, navigate) {
  return (dispatch, getState) => { 
    const currentState = getState()
    appFetch("POST", API_URL, "/user/reset-password", data, token, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: RESET_PASS, payload: null });
          navigate("/login", {replace: true})
          dispatch(showDrawer('info', {name: 'can_login' }))
          //dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Ustawiono nowe hasło. Możesz się zalogować." } });
      } else {
        dispatch(showDrawer('error', {name: result.data.error, errorCode: result.meta.ts }))
      }
    }, (error) => {
      dispatch(showDrawer('error', { error: 'generic' }))
    })
  }
}

export function otp() {
  return (dispatch, getState) => { 
    const currentState = getState()
    const data = {
      otp: {
          purpose: "PARTIAL_UPDATE"
      }
    }
    appFetch("POST", API_URL, "/user/otp", data, currentState.user?.token?.uuid ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: SET_OTP_TOKEN, payload: {} });
          dispatch(showDrawer('info', {name: 'otp_sent' }))
          //dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Kod wysłany" } });
      } else {
        dispatch(showDrawer('error', {name: result.data.error, errorCode: result.meta.ts }))
      }
    }, (error) => {
      dispatch(showDrawer('error', { error: 'generic' }))
    })
  }
}

export function updateUser(data, backToDashboard, navigate,withoutInfo = false) {
  return (dispatch, getState) => { 
    const currentState = getState()
    appFetch("POST", API_URL, "/user/partial-update", data, currentState?.user?.token.uuid ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
        if(withoutInfo) {
          
        } else {
          dispatch(showDrawer('info', {name: 'data_saved' }))

        }
        //dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Dane zostały zapisane" } });
        if(backToDashboard) {
          dispatch(getUserDetails(navigate, true))
        } else {
          dispatch(getUserDetails())
        }
      } else {
        dispatch(showDrawer('error', {name: result.data.error, errorCode: result.meta.ts }))
      }
    }, (error) => {
      dispatch(showDrawer('error', { error: 'generic' }))
    })
  }
}

export function registerGlo(data) {
  return (dispatch, getState) => { 

      appFetch("POST", API_WEBFORM_URL, "/batpartners/register", data, false, (result) => {
          if(result.status && result.status.success) {
            //dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Rejestracja udana" } });
            dispatch({ type: REGISTER_GLO_SUCCESS, payload: { } });
          } else {
            dispatch(showDrawer('error', {name: result.data.error, errorCode: result.meta.ts }))
          }
      }, (error) => {
          dispatch(showDrawer('error', { error: 'generic' }))
      })
      
  }
}

export function otpGlo(data) {
  return (dispatch, getState) => { 
    const currentState = getState()

    appFetch("POST", API_WEBFORM_URL, "/batpartners/otp", data, false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: SET_OTP_GLO_TOKEN, payload: {} });
          dispatch(showDrawer('info', {name: 'otp_sent' }))
          //dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Kod wysłany" } });
      } else {
        dispatch(showDrawer('error', {name: result.data.error, errorCode: result.meta.ts }))
      }
    }, (error) => {
      dispatch(showDrawer('error', { error: 'generic' }))
    })
  }
}
export function sendRegisterOtp(data,successCallback,errorCallback) {
  return (dispatch, getState) => { 
    const currentState = getState()
   
    appFetch("POST", API_URL, "/user/otp-register", data, false, (result) => {
      if(result.status && result.status.success) {
      
        if (successCallback && typeof successCallback === 'function') {
          successCallback();
        }
      } else {
        if (successCallback && typeof successCallback === 'function') {
          errorCallback();
        }
        dispatch(showDrawer('error', {name: result.data.error, errorCode: result.meta.ts }))
      }
    }, (error) => {
      if (successCallback && typeof successCallback === 'function') {
        errorCallback();
      }
      dispatch(showDrawer('error', { error: 'generic' }))
    })
  }
}

export function couponRegister(data) {
  return (dispatch, getState) => { 
    const currentState = getState()
    appFetch("POST", API_URL, "/user/coupon/register", data, currentState.user?.token?.uuid ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
        dispatch({ type: COUPON_REGISTER_SUCCESS, payload: data });
        dispatch(showDrawer('coupon-registered', {name: 'coupon_registered' }))
        dispatch(couponList())
      } else {
        dispatch(showDrawer('error', {name: result.data.error, errorCode: result.meta.ts }))
      }
    }, (error) => {
      dispatch(showDrawer('error', { error: 'generic' }))
    })
  }
}

export function couponList() {
  return (dispatch, getState) => { 
    const currentState = getState()

    const data = {
      limit: 100,
      offset: 0
    }

    appFetch("POST", API_URL, "/user/coupon/list", data, currentState.user?.token?.uuid ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
        console.log()
        dispatch({ type: COUPON_LIST, payload: result.data.coupons });
      } else {
        dispatch(showDrawer('error', {name: result.data.error, errorCode: result.meta.ts }))
      }
    }, (error) => {
      dispatch(showDrawer('error', { error: 'generic' }))
    })
  }
}

export function dashTimer() {
  return (dispatch, getState) => { 
    const currentState = getState()

    appFetch("POST", API_URL, "/timer/current", false, currentState.user?.token?.uuid ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
        console.log()
        dispatch({ type: DASH_TIMER, payload: result.data.timer });
      } else {
        //dispatch(showDrawer('error', {name: result.data.error, errorCode: result.meta.ts }))
      }
    }, (error) => {
      //dispatch(showDrawer('error', { error: 'generic' }))
    })
  }
}