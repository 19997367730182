import { getUI } from "../../store/reducers/ui";
import { getUser } from "../../store/reducers/user";
import "./Onboarding.scss";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { updateUser } from '../../store/actions/user';
import img1_d from "./img/desktop_co_nowego.jpg";
import img1_m from "./img/mobile_co_nowego.jpg";
import img2_d from "./img/desktop_dash.jpg";
import img2_m from "./img/mobile_dash.jpg";
import img3_d from "./img/desktop_news.jpg";
import img3_m from "./img/mobile_news.jpg";
import img4_d from "./img/desktop_sprzedaz.jpg";
import img4_m from "./img/mobile_sprzedaz.jpg";
import img5_d from "./img/desktop_aktywnosci.jpg";
import img5_m from "./img/mobile_aktywnosci.jpg";


import { useEffect, useState } from "react";
import { ReactComponent as Arrow } from "./img/arrow-svg.svg";
declare global {
  interface Window {
    defferedPrompt: any;
  }
}

export default function Onboarding() {
  const dispatch = useAppDispatch();
  const ui = useAppSelector(getUI);
  const user = useAppSelector(getUser);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const onboarding = sessionStorage.getItem("onboarding");

const handleSeen = () => {
    dispatch(updateUser({ user: { onboarding: true } }, false, false, true));
};
  useEffect(() => {
    if(!user?.onboarding  && user?.firstName) {
      setShow(true)
   
    }
  },[user?.onboarding])

 



  const slidesList = [
    {
      image: img1_d,
      image_mobile: img1_m,
      title: "Wspaniale Cię widzieć!",
      text: "Na platformie BAT Partners Hurt pojawiło się kilka nowych zmian. <strong>Zobacz, co się zmieniło!</strong>",
    },
    {
      image: img2_d,
      image_mobile: img2_m,
      title: "Widok dashboardu",
      text: "<strong>Twój dashboard jest teraz jeszcze bardziej użyteczny!</strong> Znajdziesz tam podstawowe informacje o aktualnym zadaniu i swoje statystyki, co ułatwi monitorowanie postępów.",
    },
    {
      image: img3_d,
      image_mobile: img3_m,
      title: "Nawigacja ",
      text: "Zmieniliśmy nawigację, aby dotarcie do najważniejszych podstron było szybsze i bardziej intuicyjne. <strong>Wszystko, czego potrzebujesz, jest teraz na wyciągnięcie ręki!</strong>",
    },
    {
      image: img4_d,
      image_mobile: img4_m,
      title: "Strona - Wypłać",
      text: "Znajdziesz tam swoje zasłużone nagrody oraz wypłacisz je bezpośrednio na swoje konto. <strong>Szybko&nbsp;i&nbsp;wygodnie.</strong>",
    },
    {
      image: img5_d,
      image_mobile: img5_m,
      title: "Numer konta ",
      text: "Od teraz, aby wypłacać swoje nagrody, musisz podać swój numer konta w Profilu. <strong>Twoje pieniądze trafią dokładnie tam, gdzie powinny.</strong>",
    },
  ];

  return (
    <>
      {show && (
        <div className="Onboarding">
          <div className="onboarding-overlay">
            <div className="onboarding-inner">
              <Swiper
                loop={false}
                pagination={{
                  clickable: true,
                }}
                navigation={{
                  nextEl: ".swiper-onboarding-next",
                  prevEl: ".swiper-onboarding-prev",
                }}
                modules={[Navigation, Pagination]}
                className="activities-slider"
                breakpoints={{
                  270: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                }}
                onSlideChange={(e) => setActiveSlide(e.activeIndex)}
              >
                {slidesList.map((slide: any) => (
                  <SwiperSlide key={slide.title}>
                    <div className="onboarding-slide">
                      <div className="image-top">
                        <picture>
                          <source
                            media="(max-width: 719px)"
                            srcSet={slide.image_mobile}
                          />
                          <source
                            media="(min-width: 720px)"
                            srcSet={slide.image}
                          />
                          <img
                            className="img"
                            src={slide.image_mobile}
                            loading="lazy"
                            alt={slide.title}
                          />
                        </picture>
                      </div>
                      <div className="content">
                        <h2>{slide.title}</h2>
                        <p dangerouslySetInnerHTML={{ __html: slide.text }} />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div
                className="close-btn"
                onClick={() => {
                  handleSeen();
                  setShow(false);
               
                }}
              >
                Pomiń
              </div>
              {activeSlide === slidesList.length -1 && (
                <div
                  className="swiper-close"
                  onClick={() => {
                    handleSeen();
                    setShow(false);
                  
                  }}
                >
                  <Arrow />
                </div>
              )}
              <div
                className={
                  activeSlide < slidesList.length -1
                    ? "swiper-onboarding-next"
                    : "swiper-onboarding-next hide"
                }
              >
                <Arrow />
       
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
