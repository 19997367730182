import "./CookiesFooter.scss";
const CookiesFooter = () => {

  return (
    <footer className="cookies-footer">
        <ul>
            <li><a href="/pdf/klauzula_informacy_na_bp_hurt.pdf" target="_blank">Klauzula Informacyjna</a></li>
            {/* <li><a href="#" target="_blank">Preferencje Cookies</a></li> */}
            <li><a href="/pdf/polityka_cookies_bp_hurt.pdf" target="_blank">Polityka Cookies</a></li>
        </ul>
    </footer>
  );
};

export default CookiesFooter;
