import { useForm } from "react-hook-form";
import Button from "../../../components/Button/Button";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useAppDispatch } from "../../../hooks";
type DetailsStepType = {
  handleNextStep: () => void;
  setFormValues: React.Dispatch<React.SetStateAction<undefined>>;
  formValues: any;
};

const DetailsStep = ({
  handleNextStep,
  setFormValues,
  formValues,
}: DetailsStepType) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: formValues?.firstName,
      lastName: formValues?.lastName,
      businessName: formValues?.businessName,
      referralCode: formValues?.referralCode,
      emailAddress: formValues?.emailAddress,
      mobilePhone: formValues?.mobilePhone,
    },
  });
  const dispatch = useAppDispatch();

  const onSubmit = (data: any) => {
    setFormValues((prev: any) => ({
      ...prev,
      ...data,
    }));

    handleNextStep();
  };
  const onError = (errros: any) => {
    // console.log(errros, "err");
  };

  return (
    <div className="form-step">
      <form
        className="form"
        noValidate
        autoComplete="off"
        method="POST"
        onSubmit={onSubmit}
      >
        <div className="input-item">
          <label htmlFor="firstName">Imię</label>
          <input
            placeholder="Twoje imię"
            className={errors.firstName && "error-input"}
            type="text"
            id="firstName"
            {...register("firstName", {
              required: true,
              pattern: /^[\sa-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ-]*$/,
            })}
          ></input>
          {errors.firstName && errors?.firstName?.type === "pattern" && (
            <p className="error">Nieprawidłowa wartość</p>
          )}
          {errors.firstName && errors?.firstName?.type === "required" && (
            <p className="error">Wpisz imię</p>
          )}
        </div>
        <div className="input-item">
          <label htmlFor="lastName">Nazwisko</label>
          <input
            placeholder="Kowalski"
            className={errors.lastName && "error-input"}
            type="text"
            id="lastName"
            {...register("lastName", {
              required: true,
              pattern: /^[\sa-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ-]*$/,
            })}
          ></input>
          {errors.lastName && errors?.lastName?.type === "pattern" && (
            <p className="error">Nieprawidłowa wartość</p>
          )}
          {errors.lastName && errors?.lastName?.type === "required" && (
            <p className="error">Wpisz nazwisko</p>
          )}
        </div>
        <div className="input-item">
          <label htmlFor="emailAddress">E-mail</label>
          <input
            placeholder="Twój adres e-mail"
            className={errors.emailAddress && "error-input"}
            type="text"
            id="emailAddress"
            {...register("emailAddress", {
              required: true,
              pattern:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          ></input>
          {errors.emailAddress && errors?.emailAddress?.type === "pattern" && (
            <p className="error">Nieprawidłowy adres e-mail</p>
          )}
          {errors.emailAddress && errors?.emailAddress?.type === "required" && (
            <p className="error">Wpisz adres e-mail</p>
          )}
        </div>
        <div className="input-item">
          <label htmlFor="mobilePhone">
            Telefon <br />
            <span>
              Numer telefonu potrzebny jest, aby w pełni korzystać z programu.
            </span>
          </label>
          <div className="prefix-input-wrapper">
            <span className="prefix">+48</span>
            <input
              placeholder="601602603"
              className={errors.mobilePhone && "error-input"}
              type="text"
              id="mobilePhone"
              {...register("mobilePhone", {
                required: true,
                pattern: /^\d{9}$/,
              })}
            ></input>
          </div>
          {errors.mobilePhone && errors?.mobilePhone?.type === "pattern" && (
            <p className="error">Nieprawidłowy numer telefonu</p>
          )}
          {errors.mobilePhone && errors?.mobilePhone?.type === "required" && (
            <p className="error">Wpisz numer telefonu</p>
          )}
        </div>
        <div className="input-item">
          <label htmlFor="businessName">Nazwa hurtowni</label>
          <input
            placeholder="Podaj nazwę hurtowni"
            className={errors.businessName && "error-input"}
            type="text"
            id="businessName"
            {...register("businessName", {
              required: true,
              pattern: /^[\sa-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ-]*$/,
            })}
          ></input>
          {errors.businessName && errors?.businessName?.type === "pattern" && (
            <p className="error">Nieprawidłowa wartość</p>
          )}
          {errors.businessName && errors?.businessName?.type === "required" && (
            <p className="error">Wpisz nazwę</p>
          )}
        </div>
        <div className="input-item">
          <label htmlFor="referralCode">Kod polecający</label>
          <input
            placeholder="66594"
            className={errors.referralCode && "error-input"}
            type="text"
            id="referralCode"
            {...register("referralCode", { required: true, pattern: /^.{6}$/ })}
          ></input>
          {errors.referralCode && errors?.referralCode?.type === "pattern" && (
            <p className="error">Nieprawidłowy kod polecający</p>
          )}
          {errors.referralCode && errors?.referralCode?.type === "required" && (
            <p className="error">Wpisz kod polecający</p>
          )}
        </div>
        <Button type="submit" onClick={handleSubmit(onSubmit, onError)}>
          Dalej
        </Button>
        <div className="text-center margin-20">
          <Link to="/login">Powrót do logowania</Link>
        </div>
      </form>
    </div>
  );
};
export default DetailsStep;
